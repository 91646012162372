<template>
  <div class="loginBox b-radius--5 shadow--light bgColor--white bgColor--white flex flex--column flex--center__vert pad--25">
    <div class="flex flex--column width--100 mar--bottom__1">
      <label for="login_email" class="input--label">Email</label>
      <input id="login_email" type="text" class="input--text" v-model="email"/>
    </div>
    <div class="flex flex--column width--100 mar--bottom__2">
      <label for="login_password" class="input--label">Passwort</label>
      <input id="login_password" type="password" class="input--text" v-model="password"/>
    </div>
    <AccentButton title="Anmelden" full-width="true" @onClick="login"/>
    <div class="mar--top__2 font--s16 flex flex--center clickable" @click="forgotPassword">
      Passwort vergessen?
    </div>
  </div>
</template>

<script>
import AccentButton from '@/components/AccentButton';
export default {
  name: 'LoginBox',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  components: {AccentButton},
  methods: {
    async login() {
      await this.$store.dispatch('loginUser', {
        email: this.email,
        password: this.password,
        deviceId: '',
        platform: 'web',
        strategy: 'local',
      }).then(() => {
        // this.$router.go(0);
      });
    },
    async forgotPassword() {
      if (this.email && this.email !== '') {
        await this.$store.dispatch('forgotPassword', {
          email: this.email,
        });
        // this.$store.commit('addToast', {
        //   type: 'success',
        //   title: 'Bitte schau in deine Emails.'
        // });
      } else {
        this.$store.commit('addToast', {
          type: 'warning',
          title: 'Bitte gib deine Email ein.'
        });
      }

    },
  },
}
</script>
