<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/reports')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <label for="report_post_type" class="input--label">Meldung</label>
            <input id="report_post_type" class="input--text mar--bottom__2"
                   disabled
                   :value="`${report.type}`"/>
            <label for="report_reporter" class="input--label">Gemeldete Person</label>
            <input id="report_reporter" class="input--text mar--bottom__2"
                   disabled
                   :value="`${report.affectedUser.firstName} - ${report.affectedUser.email}`"/>
            <label for="report_post_reason" class="input--label">Grund</label>
            <input id="report_post_reason" class="input--text mar--bottom__2"
                   disabled
                   :value="`${report.reason}`"/>
            <label for="report_amount" class="input--label">Zahl der Meldungen</label>
            <input id="report_amount" class="input--text mar--bottom__2"
                   disabled
                   :value="`${report.reportedByUsers.length}`"/>
            <label for="report_post" v-if="report.type === 'post'" class="input--label">Beitrag</label>
            <input v-if="report.type === 'post'"
                   class="input--text mar--bottom__2"
                   :value="report.post.title"
                   disabled
            />
            <textarea v-if="report.type === 'post'"
                      id="report_post" class="input--text height--10 mar--bottom__2"
                      :value="report.post.description"
                      disabled
            />
            <template v-if="report.post">
                <div v-for="(img, ind) in report.post.uploads" :key="img._id">
                    <div class="flex flex--center width--100 pad--2"
                         v-if="report.post && report.post.uploads[ind].file && report.post.uploads[ind].mimetype.includes('image')">
                        <img :src="`${$store.getters.URL}uploads/fetch/${report.post.uploads[ind].file}?authentication=${token}`"
                             class="width--50"/>
                    </div>
                </div>
            </template>
            <label for="report_post" v-if="report.type === 'comment'" class="input--label">Kommentar</label>
            <input v-if="report.type === 'comment'"
                   class="input--text mar--bottom__2"
                   :value="report.comment.message"
                   disabled
            />
            <div class="flex width--100">
                <AccentButton :title="report.status === 'closed' ? 'Eröffnen' : 'Erledigt'" filled="true" @onClick="update"/>
                <AccentButton title="Ignorieren" @onClick="update" v-if="report.status === 'open'"/>
                <AccentButton title="Post Deaktivieren" filled="true" danger="true" @onClick="deactivatePost(true)"
                              v-if="$route.params.type === 'post' && !report.post.deactivated"/>
                <AccentButton title="Post Reaktivieren" filled="true" danger="true"  @onClick="deactivatePost(false)"
                              v-if="$route.params.type === 'post' && report.post.deactivated"/>
                <AccentButton title="Kommentar Löschen" filled="true" danger="true" @onClick="deleteComment"
                              v-if="$route.params.type === 'comment' && report.status === 'open'"/>
                <AccentButton title="Nutzer Deaktivieren" filled="true" danger="true" @onClick="deactivateUser" v-if="report.status === 'open'"/>
            </div>
        </div>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    export default {
        name: 'ReportDetails',
        components: {AccentButton, ActionButton},
        data() {
            return {
              token: sessionStorage.getItem('DogU-AuthToken'),
              notes: '',
            };
        },
        computed: {
            report() {
                return this.$store.getters.reports.find((el) => el._id === this.$route.params.id) || {};
            },
        },
        methods: {
            update() {
                this.$store.dispatch('updateReport', {
                    id: this.$route.params.id,
                    data: {
                        'status': this.report.status === 'closed' ? 'open' : 'closed',
                    },
                });
                this.$router.go(-1);
            },
            deactivateUser() {
                let success = true;
                this.$store.dispatch('updateUser', {
                    id: this.report.user._id,
                    data: {
                        locked: true,
                    },
                }).catch(() => {
                    success = false;
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Nutzer konnte nicht gesperrt werden',
                    });
                }).then(() => {
                    if (success) this.$store.commit('addToast', {
                        type: 'success',
                        title: 'Nutzer erfolgreich gesperrt',
                    });
                })
            },
            deleteComment() {
                let success = true;
                this.$store.dispatch('deleteComment', this.report.comment._id).catch(() => {
                    success = false;
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Kommentar konnte nicht gelöscht werden',
                    });
                }).then(() => {
                    this.update();
                    if (success) this.$store.commit('addToast', {
                        type: 'success',
                        title: 'Kommentar erfolgreich gelöscht',
                    });
                });
            },
            deactivatePost(val) {
                let success = true;
                this.$store.dispatch('updatePost', {
                    id: this.report.post._id,
                    data: {
                        deactivated: val
                    },
                }).catch(() => {
                    success = false;
                    this.$store.commit('addToast', {
                        type: 'error',
                        title: 'Der Beitrag konnte nicht deaktiviert werden',
                    });
                }).then(() => {
                    if (success) this.$store.commit('addToast', {
                        type: 'success',
                        title: 'Beitrag erfolgreich deaktiviert',
                    });
                })
            },
        },
        mounted() {
            console.log(this.report.notes);
            this.notes = this.report.notes;
        },
    };
</script>
