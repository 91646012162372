<template>
    <div :id="`category-${category._id}`"
         class="categoryTile flex flex--column mar--bottom__2 relative"
         @click.self="$router.push(`/knowledge/category/${category._id}`)"
         @dragenter="handleDragEnter" @dragstart="handleDragStart" @dragend="dragged = false" @drop="handleDrop" @dragover.prevent>
        <div class="absolute width--100 height--100 b-radius--5 bgColor--primary opacity--5 left--0 front"
             v-if="hover ||dragged" @dragenter="hover = true" @dragleave="hover = false"/>
        <div class="flex flex--center__vert flex--space__hor pad--2" :id="`category-${category._id}`">
            <div class="flex flex--center__vert">
                <ion-icon name="reorder-two" class="font--s2 color--greyDark movable" @mousedown="handleMouseDown" @mouseup="handleMouseUp"/>
                <div class="mar--left__2 font--s16 font--w700 flex flex--column lineHeight--12">
                    {{ category.title }}
                    <div class="color--primary font--w500 font--s1">
                        {{ category.type }}
                    </div>
                </div>
            </div>
            <ActionButton icon="trash" light="true" @onClick="deleteCategory"/>
        </div>
        <div class="categoryTile--item flex flex--center__vert flex--space__hor"
             v-for="item in category.articles" :key="item._id">
            <div class="flex flex--center__vert">
                <div class="height--2 width--2 flex flex--center b-radius--50 bgColor--greyDark mar--right__2">
                    <ion-icon name="videocam" class="font--s2 color--white" v-if="item.type === 'Video'" />
                    <ion-icon name="browsers" class="font--s2 color--white" v-if="item.type === 'Website'" />
                </div>
                <div class="font--s16">
                    {{ item.title }}
                </div>
            </div>
            <ActionButton icon="close" light="true" @onClick="$emit('removeItem', {
                category: category._id,
                item: item._id,
            })"/>
        </div>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';
    export default {
        name: 'KnowledgeCategoryTile',
        components: {ActionButton},
        props: ['category', 'draggedEl'],
        data() {
            return {
                hover: false,
                dragged: false,
            };
        },
        computed: {
            cachedKnowledge: {
                get() {
                    return this.$store.getters.knowledge;
                },
                set(val) {
                    this.$store.commit('setKnowledge', val);
                }
            },
        },
        methods: {
            deleteCategory() {
                this.$store.dispatch('deleteKnowledgeCategory', this.category._id);
            },
            handleMouseDown() {
                document.getElementById(`category-${this.category._id}`).setAttribute('draggable', 'true');
            },
            handleMouseUp() {
                document.getElementById(`category-${this.category._id}`).setAttribute('draggable', 'false');
            },
            handleDragEnter(el) {
                if (this.draggedEl) {
                    if (this.draggedEl.split('-')[0] === 'item' && el.target.id) {
                        if (el.target.id.split('-')[0] === 'category') {
                            this.hover = true;
                        }
                    } else if (this.draggedEl.split('-')[0] === 'category' && el.target.id) {
                        this.$emit('dragEvent', {
                            type: 'reorder',
                            to: el.target.id,
                        });
                    }
                }
            },
            handleDragStart() {
                this.dragged = true;
                this.$emit('dragEl', `category-${this.category._id}`)
            },
            handleDragEnd() {
                this.dragged = false;
                this.$emit('dragEl', null);
            },
            handleDrop() {
                if (this.draggedEl.split('-')[0] === 'item') {
                    this.$emit('dragEvent', {
                        type: 'add',
                        to: `category-${this.category._id}`,
                    });
                }
                this.$emit('dragEl', null);
                this.hover = false;
            }
        }
    };
</script>
