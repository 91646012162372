<template>
    <div>
        <transition name="fade" mode="out-in">
            <AuthenticationView v-if="!authenticated" key="authentication" />
            <DashboardLoader v-else key="dashboard" />
        </transition>
    </div>
</template>
<script>
    import AuthenticationView from '@/views/AuthenticationView';
    import DashboardLoader from '@/views/DashboardLoader';
    export default {
        // MainLoader
        name: 'MainLoader',
        components: {DashboardLoader, AuthenticationView},
        computed: {
            authenticated() {
                // return sessionStorage.getItem('DogU-AuthToken');
                return this.$store.getters.authenticated;
            },
        },
        mounted() {
            if (sessionStorage.getItem('DogU-AuthToken')) {
                this.$store.commit('setAuthenticated', true);
            }
        }
    };
</script>
