<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/marker')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <div class="input--label">Typ</div>
            <v-select :options="options" :clearable="false" v-model="selection"
                      :disabled="marker != null"
                      class="input--selection width--100 mar--bottom__2"/>
            <div class="flex--column width--100">
                <div class="flex flex--center width--100 pad--2" v-if="marker && marker.uploads.length">
                    <img :src="`${$store.getters.URL}uploads/fetch/${this.marker.uploads[0].file}?authentication=${token}`"
                         class="width--50"/>
                </div>
                <UploadImage @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" v-if="$route.params.type === 'new'"/>
                <label for="marker_title" class="input--label">Titel</label>
                <input id="marker_title" class="input--text mar--bottom__2" v-model="title"/>
                <label for="marker_description" class="input--label">Beschreibung</label>
                <textarea id="marker_description" class="input--text input--text__area mar--bottom__2"  v-model="description"/>
                <label for="marker_phone" class="input--label" v-if="selection === 'Dienstleistung' || selection === 'Unterkunft'">Telefon</label>
                <input id="marker_phone" class="input--text mar--bottom__2" v-model="phone" v-if="selection === 'Dienstleistung' || selection === 'Unterkunft'"/>
                <label for="marker_email" class="input--label" v-if="selection === 'Dienstleistung' || selection === 'Unterkunft'">Email</label>
                <input id="marker_email" class="input--text mar--bottom__2" v-model="email" v-if="selection === 'Dienstleistung' || selection === 'Unterkunft'"/>
                <label for="marker_address" class="input--label">Adresse</label>
                <input id="marker_address" class="input--text mar--bottom__2" v-model="address"/>
                <div  class="input--label">Koordinaten</div>
                <div class="flex">
                    <div class="flex flex--column">
                        <label for="marker__lat" class="input--label">Latitude</label>
                        <input id="marker__lat" class="input--text mar--bottom__2" placeholder="latitude" v-model="coords[1]"/>
                    </div>
                    <div class="mar--right__1" />
                    <div class="flex flex--column">
                        <label for="marker__lng" class="input--label">Longitude</label>
                        <input id="marker__lng" class="input--text mar--bottom__2" placeholder="longitude" v-model="coords[0]"/>
                    </div>
                </div>
            </div>
            <div class="mar--bottom__2">
                <AccentButton title="Speichern" @onClick="$route.params.type !== 'new' ? updateMarker() : createMarker()"/>
            </div>
            <AccentButton danger="true" title="Löschen" v-if="marker" @onClick="deleteMarker"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    import UploadImage from "../../components/UploadImage";
    export default {
        name: 'CreateMarker',
        components: {UploadImage, AccentButton, ActionButton, vSelect},
        data() {
            return {
                token: sessionStorage.getItem('DogU-AuthToken'),
                options: ['Warnung', 'Arzt', 'Park', 'Notfallstation', 'Dienstleistung', 'Unterkunft'],
                original: ['warning', 'doctor', 'park', 'emergencyStation', 'shop', 'housing'],
                selection: 'Warnung',
                marker: null,
                title: null,
                description: null,
                address: null,
                email: null,
                phone: null,
                coords: [0, 0],
                cachedThumb: null,
                cachedThumbName: null,
            };
        },
        computed: {
            markers: {
                get() {
                    return this.$store.getters.markers;
                },
            },
        },
        methods: {

            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('files', fileList[0], fileList[0].name);
                formData.append('type', 'article');
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb() {
                if (this.cachedThumb) {
                    this.cachedThumb.append('type', 'marker');
                    this.cachedThumb.append('title', this.title);
                    this.cachedThumb.append('description', this.description);
                    if (this.phone) this.cachedThumb.append('phone', this.phone);
                    if (this.email) this.cachedThumb.append('email', this.email);
                    this.cachedThumb.append('longitude', this.coords[0] || undefined);
                    this.cachedThumb.append('latitude', this.coords[1] || undefined);
                    this.cachedThumb.append('markerType', this.original[this.options.indexOf(this.selection)]);
                    await this.$store.dispatch('uploadImage', this.cachedThumb);
                    this.cachedThumb = null;
                    this.cachedThumbName = null;
                }
            },
            updateMarker() {
                this.$store.dispatch('updateMarker', {
                    id: this.$route.params.id,
                    data: {
                        title: this.title,
                        description: this.description,
                        phone: this.phone ? this.phone : undefined,
                        email: this.email ? this.email : undefined,
                        longitude: this.coords[0],
                        latitude: this.coords[1],
                        address: this.address || undefined,
                        markerType: this.original[this.options.indexOf(this.selection)],
                    }
                }).then(() => {
                    this.$router.go(-1);
                })
            },
            createMarker() {
                if (!this.cachedThumb) {
                    this.$store.dispatch('createMarker', {
                        title: this.title,
                        description: this.description,
                        phone: this.phone ? this.phone : undefined,
                        email: this.email ? this.email : undefined,
                        longitude: this.coords[0] ? this.coords[0] : undefined,
                        latitude: this.coords[1] ? this.coords[1] : undefined,
                        address: this.address || undefined,
                        markerType: this.original[this.options.indexOf(this.selection)],
                    }).then(() => {
                        this.$router.go(-1);
                    })
                } else {
                    this.uploadThumb().then(() => {
                        this.$router.go(-1);
                    })
                }
            },
            deleteMarker() {
                this.$store.dispatch('deleteMarker', this.$route.params.id).then(() => {
                    this.$router.go(-1);
                });
            }
        },
        watch: {
            marker(newVal) {
                if (newVal !== null) {
                    this.description = newVal.description;
                    this.title = newVal.title;
                    this.phone = newVal.phone;
                    this.email = newVal.email;
                    this.coords = newVal.location.coordinates;
                    this.address = `${newVal.address.street} ${newVal.address.houseNumber}, ${newVal.address.postcode} ${newVal.address.city}`;
                    this.type = this.options[this.original.indexOf(newVal.markerType)];
                    this.selection = this.type;
                }
            }
        },
        mounted() {
            if (this.$route.params.type !== 'new') {
                this.marker = this.markers.find((el) => el._id === this.$route.params.id);
            }
        }
    };
</script>
