export default {
  URL: state => state.URL,
  authenticated: state => state.authenticated,
  token: state => state.token,
  toasts: state => state.toasts,
  users: state => state.users,
  totalUsers: state => state.totalUsers,
  knowledge: state => state.knowledge,
  knowledgeCategories: state => state.knowledgeCategories,
  courses: state => state.courses,
  courseCategories: state => state.courseCategories,
  posts: state => state.posts,
  totalPosts: state => state.totalPosts,
  dogs: state => state.dogs,
  totalDogs: state => state.totalDogs,
  dogBreeds: state => state.dogBreeds,
  totalDogBreeds: state => state.totalDogBreeds,
  markers: state => state.markers,
  totalMarkers: state => state.totalMarkers,
  reports: state => state.reports,
  totalReports: state => state.totalReports,
  forumCategories: state => state.forumCategories,
  totalForumCategories: state => state.totalForumCategories,
  interests: state => state.interests,
  totalInterests: state => state.totalInterests,
  vaccines: state => state.vaccines,
  totalVaccines: state => state.totalVaccines,
  announcements: state => state.announcements,
  totalAnnouncements: state => state.totalAnnouncements,
  skills: state => state.skills,
  totalSkills: state => state.totalSkills,
  detailedSkill: state => state.detailedSkill,
  OKourseCourses: state => state.OKourseCourses,
  notifications: state => state.notifications,
  totalNotifications: state => state.totalNotifications,
  ads: state => state.ads,
  totalAds: state => state.totalAds,
  product: state => state.product,
  coopPartners: state => state.coopPartners,
  totalCoopPartners: state => state.totalCoopPartners,
  coupons: state => state.coupons,
  totalCoupons: state => state.totalCoupons,
  coopRecords: state => state.coopRecords,
  coopCoupons: state => state.coopCoupons,
  couponCounters: state => state.couponCounters,
  hanseMerkurCoopRecordsTotal: state => state.hanseMerkurCoopRecordsTotal,
  memberCount: state => state.memberCount,
}
