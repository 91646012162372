var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"grid width--100 height--100__abs dashboard bgColor--greyLight relative overflowHidden"},[_c('div',{staticClass:"bgColor--white height--100__abs dashboard--nav__wrapper",class:{ 'dashboard--nav__out': _vm.navOut }},[_c('div',{staticClass:"height--100 shadow--light grid dashboard--nav relative"},[_c('div',{staticClass:"pad--2 flex flex--center height--100 width--100 relative"},[_c('img',{staticClass:"height--100",attrs:{"src":require("../assets/images/Logo.png")}}),_c('div',{staticClass:"dashboard--nav__trigger flex--center bgColor--white shadow--light absolute",on:{"click":function($event){_vm.navOut ? (_vm.navOut = false) : (_vm.navOut = true)}}},[_c('button',{staticClass:"burgerMenu burgerMenu--spin noDec",class:{ 'is-active': !_vm.navOut },attrs:{"type":"button"},on:{"click":function($event){!_vm.navOut ? (_vm.navOut = false) : (_vm.navOut = true)}}},[_vm._m(0)])])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === '',
            'color--white': _vm.tab[1] === '',
          },on:{"click":function($event){_vm.tab[1] === '' ? null : _vm.$router.push('/')}}},[_vm._v(" Home ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'notifications',
            'color--white': _vm.tab[1] === 'notifications',
          },on:{"click":function($event){_vm.tab[1] === 'notifications' ? null : _vm.$router.push('/notifications')}}},[_vm._v(" Benachrichtigungen ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'ads',
            'color--white': _vm.tab[1] === 'ads',
          },on:{"click":function($event){_vm.tab[1] === 'ads' ? null : _vm.$router.push('/ads')}}},[_vm._v(" Werbung ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'knowledge',
            'color--white': _vm.tab[1] === 'knowledge',
          },on:{"click":function($event){_vm.tab[1] === 'knowledge' ? null : _vm.$router.push('/knowledge')}}},[_vm._v(" Wissensangebot ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'courses',
            'color--white': _vm.tab[1] === 'courses',
          },on:{"click":function($event){_vm.tab[1] === 'courses' ? null : _vm.$router.push('/courses')}}},[_vm._v(" Kursangebot ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'overview',
            'color--white': _vm.tab[1] === 'overview',
          },on:{"click":function($event){_vm.tab[1] === 'overview' ? null : _vm.$router.push('/overview')}}},[_vm._v(" Übersicht ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'coop-partners',
            'color--white': _vm.tab[1] === 'coop-partners',
          },on:{"click":function($event){_vm.tab[1] === 'coop-partners' ? null : _vm.$router.push('/coop-partners')}}},[_vm._v(" Kooperationspartner ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'reports',
            'color--white': _vm.tab[1] === 'reports',
          },on:{"click":function($event){_vm.tab[1] === 'reports' ? null : _vm.$router.push('/reports')}}},[_vm._v(" Reports ")])]),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition",class:{
            'bgColor--primary': _vm.tab[1] === 'marker',
            'color--white': _vm.tab[1] === 'marker',
          },on:{"click":function($event){_vm.tab[1] === 'marker' ? null : _vm.$router.push('/marker')}}},[_vm._v(" Marker ")])]),_c('div'),_c('div',{staticClass:"flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item",on:{"click":_vm.logout}},[_c('div',{staticClass:"flex flex--center pad--1 b-radius--5 width--100 transition"},[_vm._v(" Abmelden ")])])])]),_c('transition',{attrs:{"name":"fade"}},[(!_vm.navOut)?_c('div',{staticClass:"dashboard--nav__backdrop absolute",on:{"click":function($event){_vm.navOut = true}}}):_vm._e()]),_c('div',{staticClass:"overflowAuto"},[_c('div',{staticClass:"dashboard--nav__placeholder"}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('router-view')],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"burgerMenu-box"},[_c('span',{staticClass:"burgerMenu-inner"})])}]

export { render, staticRenderFns }