<template>
    <div :id="`item-${item._id}`"
         @click.self="$router.push(`/knowledge/item/${item._id}`)"
         class="courseTile flex flex--center__vert flex--space__hor pad--2 mar--bottom__1 relative"
         @dragstart="handleDragStart" @dragend="handleDragEnd">
        <div class="absolute width--100 height--100 b-radius--5 bgColor--primary opacity--5 left--0" v-if="dragged"/>
        <div class="flex flex--center__vert">
            <ion-icon name="reorder-two" class="font--s2 color--greyDark movable" @mousedown="handleMouseDown" @mouseup="handleMouseUp"/>
            <div class="height--2 width--2 flex flex--center b-radius--50 bgColor--greyDark mar--left__2">
                <ion-icon name="videocam" class="font--s2 color--white" v-if="item.type === 'Video'" />
                <ion-icon name="browsers" class="font--s2 color--white" v-if="item.type === 'Website'" />
            </div>
            <div class="mar--left__2 font--s16">
                {{ item.title }}
            </div>
        </div>
        <ActionButton icon="trash" light="true" @onClick="$emit('deleteItem')"/>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';

    export default {
        name: 'KnowledgeItemTile',
        components: {ActionButton},
        props: ['item'],
        data() {
            return{
                dragged: false,
            };
        },
        methods: {
            handleMouseDown() {
                document.getElementById(`item-${this.item._id}`).setAttribute('draggable', 'true');
            },
            handleMouseUp() {
                document.getElementById(`item-${this.item._id}`).setAttribute('draggable', 'false');
            },
            handleDragStart(el) {
                this.dragged = true;
                el.dataTransfer.setData("text/plain", el.target.id);
                this.$emit('dragEl', `item-${this.item._id}`);
            },
            handleDragEnd() {
                this.dragged = false;
                this.$emit('dragEl', null);
            },
        }
    };
</script>
