<template>
  <div class="flex flex--column flex--center__vert width--100 height--100">
    <div class="dashboard--header">
      <ActionButton icon="arrow-back" @onClick="$router.push('/ads')"/>
    </div>
    <div class="dashboard--content pad--2 flex flex--column flex--grow height--100">

      <div class="flex flex--center width--100 pad--2" v-if="upload && upload.file && upload.mimetype.includes('image')">
        <img :src="`${$store.getters.URL}uploads/fetch/${this.upload.file}?authentication=${token}`"
             class="width--50"/>
      </div>
      <div class="flex flex--center width--100 pad--2" v-if="upload && upload.file && upload.mimetype.includes('video')">
        <video autoplay :src="`${$store.getters.URL}uploads/fetch/${this.upload.file}?authentication=${token}`" />
      </div>

      <UploadImage @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName" v-if="!upload"/>
      <label for="ad_title" class="input--label">Titel</label>
      <input id="ad_title" class="input--text mar--bottom__2" v-model="title"/>
      <label for="ad_description" class="input--label">Beschreibung</label>
      <textarea id="ad_description" class="input--text height--min__30 mar--bottom__2" v-model="description" />
      <div class="flex">
        <div class="flex flex--column mar--right__2">
          <div class="input--label">Ad Target Intern</div>
          <input id="ad_target"
                 type="checkbox"
                 :value="internal"
                 @input="toggleAdTarget" class="input--checkbox pad--05"/>
          <label for="ad_target" class="flex flex--center pad--1">
            <div class="width--100 circle height--100 bgColor--primary"  v-if="internal"/>
          </label>
        </div>
        <div class="flex flex--column mar--right__2">
          <div class="input--label">Ad Publizieren</div>
          <input id="ad_publish"
                 type="checkbox"
                 :value="publish"
                 @input="toggleAdPublish" class="input--checkbox pad--05"/>
          <label for="ad_publish" class="flex flex--center pad--1">
            <div class="width--100 circle height--100 bgColor--primary"  v-if="publish"/>
          </label>
        </div>
      </div>

      <div class="flex flex--column" v-if="!internal">

        <label for="ad_link" class="input--label">URL</label>
        <input id="ad_link" class="input--text mar--bottom__2" v-model="url"/>
      </div>
      <div class="flex flex--column" v-if="internal">
        <div class="input--label">Aktion</div>
        <v-select :options="commands" :clearable="false" v-model="selectedCommand" class="input--selection width--100 mar--bottom__2"/>
        <label for="ad_id" class="input--label">Content ID</label>
        <input id="ad_id" class="input--text mar--bottom__2" v-model="id"/>
      </div>
      <AccentButton :title="'Speichern'" @onClick="save" class="mar--bottom__1"/>
      <div class="flex flex--end__hor" v-if="$route.params.id !== 'new'">
        <div>

          <AccentButton :danger="true" :title="'Löschen'" @onClick="onDelete" class="mar--bottom__2"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue-select/dist/vue-select.css';
import vSelect from 'vue-select'
import ActionButton from '@/components/ActionButton';
import AccentButton from '@/components/AccentButton';
import UploadImage from "@/components/UploadImage";
export default {
  name: 'CreateAdView',
  components: {UploadImage, AccentButton, ActionButton, vSelect},
  data() {
    return {
      cachedThumb: null,
      cachedThumbName: null,
      token: sessionStorage.getItem('DogU-AuthToken'),
      title: null,
      description: null,
      publish: null,
      internal: false,
      deactivated: false,
      url: null,
      id: null,
      upload: null,
      selectedCommand: null,
      commands: [
          'openLexikon',
          'openActivities',
          'openPost',
          'openBadges',
          'openCourse',
          'openFreeCourse',
          'openForumPost',
          'openFeedingAdvice',
          'openPersonalityTest',
      ],
    };
  },
  computed: {
    OKourses() {
      return this.$store.getters.OKourseCourses;
    },
    ads() {
      return this.$store.getters.ads;
    },
  },
  methods: {
    toggleAdTarget() {
      this.internal = !this.internal;
      console.log(this.internal);
    },
    toggleAdPublish() {
      this.publish = !this.publish;
    },
    cacheThumb(fileList) {
      const formData = new FormData();
      formData.append('files', fileList[0], fileList[0].name);
      this.cachedThumb = formData;
      this.cachedThumbName = fileList[0].name;
      this.changedBreedImg = true;
    },
    async onDelete() {
      if (this.$route.params.id !== 'new') {
        await this.$store.dispatch('deleteAd', this.$route.params.id);
        this.$router.push('/ads');
      }
    },
    async save() {
      let success = true;
      if (this.$route.params.id !== 'new') {
        if (this.internal) {
          this.$store.dispatch('updateAd', {
            id: this.$route.params.id,
            data: {
              title: this.title,
              description: this.description,
              publish: this.publish,
              openInternal: {
                command: this.selectedCommand,
                userId: this.selectedCommand === 'openProfile' ? this.id : undefined,
                postId: this.selectedCommand === 'openPost' || this.selectedCommand === 'openForumPost' ? this.id : undefined,
                courseId: this.selectedCommand === 'openCourse'  || this.selectedCommand === 'openFreeCourse' ? this.id : undefined,
              }
            }
          })
        } else {
          this.$store.dispatch('updateAd', {
            id: this.$route.params.id,
            data: {
              title: this.title,
              description: this.description,
              publish: this.publish,
              openExternal: {
                url: this.url,
              }
            }
          })
        }
      } else {
        if (!this.title || !this.description) {
          this.$store.commit('addToast', {
            type: 'warning',
            title: 'Bitte gib alle Informationen ein'
          });
          return;
        }
        if (this.cachedThumb) {
          this.cachedThumb.append('type', 'ad');
          this.cachedThumb.append('title', this.title);
          this.cachedThumb.append('description', this.description);
          const res = await this.$store.dispatch('uploadImage', this.cachedThumb);
          console.log(res);
          if (res && res._id) {
            this.cachedThumb = null;
            this.cachedThumbName = null;
            if (this.internal && this.selectedCommand) {
              this.$store.dispatch('updateAd', {
                id: res._id,
                data: {
                  title: this.title,
                  description: this.description,
                  publish: this.publish,
                  openInternal: {
                    command: this.selectedCommand,
                    userId: this.selectedCommand === 'openProfile' ? this.id : undefined,
                    postId: this.selectedCommand === 'openPost' || this.selectedCommand === 'openForumPost' ? this.id : undefined,
                    courseId: this.selectedCommand === 'openCourse'  || this.selectedCommand === 'openFreeCourse' ? this.id : undefined,
                  }
                }
              })
            } else if (!this.internal && this.url) {
              this.$store.dispatch('updateAd', {
                id: res._id,
                data: {
                  title: this.title,
                  description: this.description,
                  publish: this.publish,
                  openExternal: {
                    url: this.url,
                  }
                }
              })
            }
          }
        }
      }
      if (success) this.$router.push('/ads');
    }
  },
  async beforeMount() {
    await this.$store.dispatch('retrieveOKourseCourses');
    if (this.$route.params.id !== 'new') {
      const ad = this.ads.find((el) => el._id === this.$route.params.id);
      console.log(ad);
      this.title = ad.title;
      this.description = ad.description;
      this.upload = ad.uploads[0];
      this.publish = ad.publish;
      this.deactivated = ad.deactivated;
      this.internal = !!ad.openInternal;
      this.selectedCommand = ad.openInternal != null ? ad.openInternal.command : null;
      this.id = ad.openInternal != null ? ad.openInternal.command === 'openCourse' || ad.openInternal.command === 'openFreeCourse' ? ad.openInternal.courseId : ad.openInternal.command === 'openPost' || ad.openInternal.command === 'openForumPost' ? ad.openInternal.postId : ad.openInternal.command === 'openProfile' ? ad.openInternal.userId : null : null;
      this.url = ad.openExternal ? ad.openExternal.url : null;
    }
  },
};
</script>

