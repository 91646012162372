<template>
    <div :id="`course-${course._id}`"
         @click.self="$router.push(`/courses/course/${course._id}`)"
         class="courseTile flex flex--center__vert flex--space__hor pad--2 mar--bottom__1 relative"
         @dragstart="handleDragStart" @dragend="handleDragEnd">
        <div class="absolute width--100 height--100 b-radius--5 bgColor--primary opacity--5 left--0" v-if="dragged"/>
        <div class="flex">
            <ion-icon name="reorder-two" class="font--s2 color--greyDark movable" @mousedown="handleMouseDown" @mouseup="handleMouseUp"/>
            <div class="mar--left__2 font--s16">
                {{ course.title }}
            </div>
        </div>
        <ActionButton icon="trash" light="true" @onClick="$emit('deleteCourse')"/>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';
    export default {
        name: 'CourseTile',
        components: {ActionButton},
        props: ['course'],
        data() {
            return{
                dragged: false,
            };
        },
        methods: {
            deleteCourse() {
                this.$store.dispatch('deleteCourse', this.course._id);
            },
            handleMouseDown() {
                document.getElementById(`course-${this.course._id}`).setAttribute('draggable', 'true');
            },
            handleMouseUp() {
                document.getElementById(`course-${this.course._id}`).setAttribute('draggable', 'false');
            },
            handleDragStart(el) {
                this.dragged = true;
                el.dataTransfer.setData("text/plain", el.target.id);
                this.$emit('dragEl', `course-${this.course._id}`);
            },
            handleDragEnd() {
                this.dragged = false;
                this.$emit('dragEl', null);
            },
        }
    };
</script>
