import axios from 'axios'
import store from '../store'

import { HANSE_MERKUR } from '../consts'

const BASE_URL =
  process.env.VUE_APP_API_BASE_URL || 'https://api.doguniversity.app'

const BREEDS = 'dogbreeds/'
const USERS = 'users/'
const DOGS = 'dogs/'
const FORUMCATEGORIES = 'categories/'
const INTERESTS = 'interests/'
const UPLOAD = 'uploads/'
const MARKERS = 'markers/'
const VACCINES = 'vaccines/'
const ANNOUNCEMENTS = 'announcements/'
const SKILLS = 'skills/'
const REPORTS = 'reports/'
const COURSES = 'courses/'
const ARTICLES = 'articles/'
const ARTICLESFEED = 'articlesfeed/'
const NOTIFICATIONS = 'notifications/'
const ADS = 'ads/'
const POSTS = 'posts/'
const COMMENTS = 'comments/'
const LEVELS = 'levels/'
const PRODUCTS = 'products/'
const COOPPARTNERS = 'coop-partners/'
const COUPONS = 'coupons/'
const COOPRECORDS = 'coop-records/'

axios.defaults.headers.common[
  'Authorization'
] = `Bearer ${sessionStorage.getItem('DogU-AuthToken')}`

axios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  function(error) {
    console.log(error.response.status)
    if (error.response.status === 401) {
      sessionStorage.clear()
      store.commit('setAuthenticated', false)
    }
    if (error.response.data) {
      store.commit('addToast', {
        type: 'error',
        title: error.response.data.msg,
      })
    } else {
      store.commit('addToast', {
        type: 'error',
        title: 'Es ist ein Fehler aufgetreten.',
      })
    }
    return Promise.reject(error)
  },
)

/** Builds the query string for the API */
function queryOpts({ limit, skip, attribute, asc, search }) {
  let tmp = []
  if (limit) tmp.push(`$limit=${limit}`)
  if (skip) tmp.push(`$skip=${skip}`)
  if (attribute && asc) tmp.push(`$sort[${attribute}]=${asc}`)
  if (search) tmp.push(`search=${search}`)
  return tmp.join('&')
}

export default {
  // UPLOADS ------------------------------------------------------------------------------------------

  async uploadImage(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${UPLOAD}`, data)
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  // Users ---------------------------------------------------------------------------------------------
  async loginUser({ commit }, data) {
    try {
      const res = await axios.post(`${BASE_URL}/authentication`, data)
      if (res.data.user.roles.includes('admin')) {
        sessionStorage.setItem('DogU-AuthToken', res.data.accessToken)
        axios.defaults.headers.common[
          'Authorization'
        ] = `Bearer ${sessionStorage.getItem('DogU-AuthToken')}`
        commit('setAuthenticated', true)
      } else {
        commit('addToast', {
          type: 'error',
          title: 'Sie sind kein Administrator',
        })
      }
    } catch (e) {
      console.log(e)
    }
  },
  async logoutUser({ getters }) {
    try {
      const res = await axios.delete(
        `${BASE_URL}/authentication/${getters.token}`,
      )
      console.log(res.data)
    } catch (e) {
      console.log(e)
    }
  },
  async retrieveAllUsers({ commit }, { limit, skip, attribute, asc, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/${USERS}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setUsers', res.data.data)
      commit('setTotalUsers', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createAdmin(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/${USERS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async createUser(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/${USERS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async forgotPassword(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/${USERS}password`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateUser(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/${USERS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteUser(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/${USERS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // Dogs ----------------------------------------------------------------------------------------------
  async retrieveAllDogs({ commit }, { limit, skip, attribute, asc, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${DOGS}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}&$populate=user&$populate=dogbreed`,
      )
      commit('setDogs', res.data.data)
      commit('setTotalDogs', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },

  // Dog Breeds ----------------------------------------------------------------------------------------
  async retrieveDogBreeds({ commit }, { skip, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${BREEDS}?${queryOpts({ limit: 50, skip, search })}`,
      )
      commit('setDogBreeds', res.data.data)
      commit('setTotalDogBreeds', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createDogBreed(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${BREEDS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateDogBreed(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${BREEDS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteDogBreed(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${BREEDS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },
  async uploadBreedImage(_, data) {
    try {
      await axios.post(`${BASE_URL}/v1/${UPLOAD}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  // Posts --------------------------------------------------------------------------------------------
  async retrieveAllPosts({ commit }, { limit, skip, attribute, asc, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${POSTS}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setPosts', res.data.data)
      commit('setTotalPosts', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async deletePost(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${POSTS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },
  async updatePost(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${POSTS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteComment(_, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${COMMENTS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // Forum Categories ---------------------------------------------------------------------------------
  async retrieveForumCategories(
    { commit },
    { limit, skip, attribute, asc, search },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${FORUMCATEGORIES}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setForumCategories', res.data.data)
      commit('setTotalForumCategories', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createForumCategory(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${FORUMCATEGORIES}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },

  async uploadForumCategoryImage(_, data) {
    try {
      await axios.post(`${BASE_URL}/v1/${UPLOAD}`, data)
    } catch (e) {
      console.log(e)
    }
  },

  async updateForumCategory(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${FORUMCATEGORIES}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },

  // Interests ----------------------------------------------------------------------------------------
  async retrieveInterests({ commit }, { limit, skip, attribute, asc, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${INTERESTS}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      console.log(res.data.data)
      commit('setInterests', res.data.data)
      commit('setTotalInterests', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createInterest(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${INTERESTS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateInterest(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${INTERESTS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteInterest(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${INTERESTS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // Interests ----------------------------------------------------------------------------------------
  async retrieveAnnouncements(
    { commit },
    { limit, skip, attribute, asc, search },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${ANNOUNCEMENTS}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setAnnouncements', res.data.data)
      commit('setTotalAnnouncements', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createAnnouncement(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${ANNOUNCEMENTS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateAnnouncement(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${ANNOUNCEMENTS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteAnnouncement(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${ANNOUNCEMENTS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // Reports --------------------------------------------------------------------------------------------
  async retrieveAllReports(
    { commit },
    { limit, skip, status, attribute, asc, search },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${REPORTS}admin?status=${status}&${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setReports', res.data.data)
      commit('setTotalReports', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async updateReport(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${REPORTS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  // Markers --------------------------------------------------------------------------------------------
  async retrieveAllMarkers(
    { commit },
    { limit, skip, type, attribute, asc, search },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${MARKERS}?markerType=${type}&${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setMarkers', res.data.data)
      commit('setTotalMarkers', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createMarker(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${MARKERS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateMarker({ dispatch }, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${MARKERS}${id}`, data)
      dispatch('retrieveAllMarkers', {
        limit: 50,
        skip: 0,
      })
    } catch (e) {
      console.log(e)
    }
  },
  async deleteMarker({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${MARKERS}${id}`)
      dispatch('retrieveAllMarkers', {
        limit: 50,
        skip: 0,
      })
    } catch (e) {
      console.log(e)
    }
  },

  // Vaccines --------------------------------------------------------------------------------------------
  async retrieveAllVaccines(
    { commit },
    { limit, skip, attribute, asc, search },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${VACCINES}?${queryOpts({
          limit,
          skip,
          attribute,
          asc,
          search,
        })}`,
      )
      commit('setVaccines', res.data.data)
      commit('setTotalVaccines', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createVaccine(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${VACCINES}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async updateVaccine(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${VACCINES}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteVaccine(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${VACCINES}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // Skills --------------------------------------------------------------------------------------------
  async retrieveSkills({ commit }, { attribute, asc, search }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${SKILLS}?${queryOpts({ attribute, asc, search })}`,
      )
      commit('setSkills', res.data)
      commit('setTotalSkills', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },
  async createSkill(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${SKILLS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async createLevel(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${LEVELS}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },
  async deleteLevel(_, levelID) {
    try {
      await axios.delete(`${BASE_URL}/v1/${LEVELS}/${levelID}`)
    } catch (e) {
      console.log(e)
    }
  },
  async updateLevel(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${LEVELS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async updateSkill(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${SKILLS}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },
  async deleteSkill(_, { id }) {
    try {
      await axios.delete(`${BASE_URL}/v1/${SKILLS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  // OKourse ---------------------------------------------------------------------------------------------

  async retrieveOKourseCourses({ commit }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/okourse/admin`)
      commit('setOKourseCourses', res.data)
    } catch (e) {
      console.log(e)
    }
  },

  // Courses -----------------------------------------------------------------------------------------------

  async retrieveCourses({ commit }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${COURSES}?${queryOpts({ limit: 500 })}`,
      )
      commit('setCourses', res.data.data)
    } catch (e) {
      console.log(e)
    }
  },

  async createCourse({ dispatch }, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${COURSES}`, data)
      dispatch('retrieveCourses')
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },

  async deleteCourse({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${COURSES}${id}`)
      dispatch('retrieveCourses')
    } catch (e) {
      console.log(e)
    }
  },

  async updateCourse({ dispatch }, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${COURSES}${id}`, data)
      dispatch('retrieveCourses')
    } catch (e) {
      console.log(e)
    }
  },

  async retrieveCourseCategories({ commit }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/offersfeed`)
      commit('setCourseCategories', res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async createCourseCategory(_, data) {
    try {
      await axios.post(`${BASE_URL}/v1/offersfeed`, data)
    } catch (e) {
      console.log(e)
    }
  },

  async deleteCourseCategory({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/offersfeed/${id}`)
      dispatch('retrieveCourseCategories')
    } catch (e) {
      console.log(e)
    }
  },

  async updateCourseCategory(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/offersfeed/${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },

  async retrieveSubscription({ commit }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/${PRODUCTS}`)
      commit('setProduct', res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async updateSubscription({ dispatch }, data) {
    try {
      await axios.post(`${BASE_URL}/v1/${PRODUCTS}`, data)
      dispatch('retrieveSubscription')
    } catch (e) {
      console.log(e)
    }
  },

  // KNOWLEDGE ----------------------------------------------------------------------------------------------

  async retrieveKnowledgeItems({ commit }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${ARTICLES}?${queryOpts({ limit: 500 })}`,
      )
      commit('setKnowledge', res.data.data)
    } catch (e) {
      console.log(e)
    }
  },

  async createKnowledgeItem(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${ARTICLES}`, data)
      return res.data._id
    } catch (e) {
      console.log(e)
    }
  },

  async deleteKnowledgeItem({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${ARTICLES}${id}`)
      dispatch('retrieveKnowledgeItems')
    } catch (e) {
      console.log(e)
    }
  },

  async updateKnowledgeItem({ dispatch }, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${ARTICLES}${id}`, data)
      dispatch('retrieveKnowledgeItems')
    } catch (e) {
      console.log(e)
    }
  },

  async retrieveKnowledgeCategories({ commit }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/${ARTICLESFEED}`)
      commit('setKnowledgeCategories', res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async createKnowledgeCategory(_, data) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${ARTICLESFEED}`, data)
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  async deleteKnowledgeCategory({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${ARTICLESFEED}${id}`)
      dispatch('retrieveKnowledgeCategories')
    } catch (e) {
      console.log(e)
    }
  },

  async updateKnowledgeCategory(_, { id, data }) {
    try {
      await axios.patch(`${BASE_URL}/v1/${ARTICLESFEED}${id}`, data)
    } catch (e) {
      console.log(e)
    }
  },

  // NOTIFICATIONS ------------------------------------------------------------------

  async retrieveNotifications(
    { commit },
    { amount, skip, search, attribute, asc },
  ) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${NOTIFICATIONS}?${queryOpts({
          limit: amount,
          skip,
          search,
          attribute,
          asc,
        })}`,
      )
      commit('setNotifications', res.data.data)
      commit('setTotalNotifications', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },

  async createNotification(_, { type, data }) {
    try {
      const res = await axios.post(
        `${BASE_URL}/v1/${NOTIFICATIONS}?type=${type}`,
        data,
      )
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  // NOTIFICATIONS ------------------------------------------------------------------

  async retrieveAds({ commit }, { limit, skip, search, attribute, asc }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${ADS}?${queryOpts({
          limit,
          skip,
          search,
          attribute,
          asc,
        })}`,
      )
      commit('setAds', res.data.data)
      commit('setTotalAds', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },

  async deleteAd(_, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${ADS}${id}`)
    } catch (e) {
      console.log(e)
    }
  },

  async updateAd(_, { id, data }) {
    try {
      const res = await axios.patch(`${BASE_URL}/v1/${ADS}${id}`, data)
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  async getAllCoopPartners({ commit }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/${COOPPARTNERS}`)
      commit('setCoopPartners', res.data.data)
      commit('setTotalCoopPartners', res.data.total)
      console.info(res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async createCoopPartner({ dispatch }, { data }) {
    try {
      const res = await axios.post(`${BASE_URL}/v1/${COOPPARTNERS}`, data)
      dispatch('getAllCoopPartners')
      return res.data
    } catch (e) {
      console.log(e)
    }
  },

  async editCoopPartner({ dispatch }, { id, data }) {
    // TODO {
    //     "name": "GeneralError",
    //     "message": "DELETE method is not allowed on this route. Use PATCH and set isActive to false instead.",
    //     "code": 500,
    //     "className": "general-error",
    //     "data": {},
    //     "errors": {}
    // }
    try {
      await axios.patch(`${BASE_URL}/v1/${COOPPARTNERS}${id}`, data)
      dispatch('getAllCoopPartners')
    } catch (e) {
      console.log(e)
    }
  },

  async deleteCoopPartner({ dispatch }, id) {
    try {
      await axios.delete(`${BASE_URL}/v1/${COOPPARTNERS}${id}`)
      dispatch('getAllCoopPartners')
    } catch (e) {
      console.log(e)
    }
  },

  async getAllCouponsForCoopPartner({ commit }, { id }) {
    try {
      const res = await axios.get(`${BASE_URL}/v1/${COUPONS}?partner=${id}`)
      // TODO add to state
      commit('setCoupons', res.data.data)
      commit('setTotalCoupons', res.data.total)
      console.info(res.data)
    } catch (e) {
      console.log(e)
    }
  },

  async getMemberCount({ commit, getters }, { id }) {
    try {
      const assignedRes = await axios.get(
        `${BASE_URL}/v1/${COUPONS}?partner=${id}&$limit=0&isAssigned=true`,
      )
      let memberCount = getters.memberCount
      if (memberCount.find(m => m._id === id)) {
        memberCount = memberCount.filter(m => m._id !== id)
      }
      memberCount = [...memberCount, { _id: id, count: assignedRes.data.total }]
      commit('setMemberCount', memberCount)
    } catch (e) {
      console.log(e)
    }
  },

  async getCouponsCountersForCoopPartner({ commit }, { id }) {
    try {
      const allRes = await axios.get(
        `${BASE_URL}/v1/${COUPONS}?partner=${id}&$limit=0`,
      )
      const assignedRes = await axios.get(
        `${BASE_URL}/v1/${COUPONS}?partner=${id}&$limit=0&isAssigned=true`,
      )

      const counters = {
        total: allRes.data.total,
        used: assignedRes.data.total,
      }
      console.info(counters)
      commit('setCouponCounters', counters)
      return counters
    } catch (e) {
      console.log(e)
    }
  },

  async uploadCouponsAsCsvFile(_, { id, file }) {
    try {
      await axios.post(`${BASE_URL}/v1/${COUPONS}?partner=${id}`, file)
    } catch (e) {
      console.log(e)
    }
  },

  async getCoopRecordsForUser({ commit }, { userId }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${COOPRECORDS}?$populate=partner&$limit=1000&user=${userId}`,
      )
      commit('setCoopRecords', res.data.data)
    } catch (e) {
      console.log(e)
    }
  },

  async patchCoopRecord({ commit }, { id, data }) {
    try {
      const res = await axios.patch(`${BASE_URL}/v1/${COOPRECORDS}${id}`, data)
      commit('getCoopRecordsForUser', { userId: res.data.user })
    } catch (e) {
      console.log(e)
    }
  },

  async getHanseMerkurCoopRecordsTotal({ commit }) {
    try {
      const res = await axios.get(
        `${BASE_URL}/v1/${COOPRECORDS}?$limit=0&partner=${HANSE_MERKUR}`,
      )
      console.log('HM', res.data.total)
      commit('setHanseMerkurCoopRecordsTotal', res.data.total)
    } catch (e) {
      console.log(e)
    }
  },

  async getCouponsForUser({ commit }, { coopPartnerIds, userId }) {
    try {
      const url = `${BASE_URL}/v1/${COUPONS}?${coopPartnerIds
        .map(id => 'partner[$in][]=' + id)
        .join('&')}&assignedTo=${userId}&$populate=partner&$limit=1000`
      const res = await axios.get(url)
      commit('setCoopCoupons', res.data.data)
    } catch (e) {
      console.log(e)
    }
  },
}
