<template>
    <div class="width--100 height--100 flex flex--center">
        <div class="grid landingView">
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/notifications')">
                <ion-icon name="notifications" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Benachrichtigungen
                </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/ads')">
              <ion-icon name="megaphone" class="color--white font--s8"/>
              <div class="font--s16 color--white">
                Werbung
              </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/knowledge')">
                <ion-icon name="bulb" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Wissensangebot
                </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/courses')">
                <ion-icon name="play-circle" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Kursangebot
                </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/overview')">
                <ion-icon name="albums" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Übersicht
                </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/reports')">
                <ion-icon name="alert-circle" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Reports
                </div>
            </div>
            <div class="bgColor--primaryLight shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 @click="$router.push('/marker')">
                <ion-icon name="location" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Marker
                </div>
            </div>
            <a class="bgColor--secondary shadow--light b-radius--5 clickable transition relative flex flex--center flex--column"
                 href="https://okourse.com/" target="_blank">
                <ion-icon name="desktop" class="color--white font--s8"/>
                <div class="font--s16 color--white">
                    Zu OKourse
                </div>
            </a>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'LandingView',
    };
</script>
