export const reorderArray = (array, oldInd, newInd) => {
    const moveEl = array[oldInd];
    array.splice(oldInd, 1);
    if (newInd >= array.length) {
        array.push(moveEl);
    } else {
        array.splice(newInd, 0, moveEl);
    }
    return array;
};
