<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/courses')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <label for="abo_title" class="input--label">Aboname</label>
            <input id="abo_title" class="input input--text mar--bottom__1" v-model.trim="product.title"/>
            <label for="abo_price" class="input--label">Preis (in €)</label>
            <input id="abo_price" type="number" class="input input--text mar--bottom__1"  v-model.number="product.price"/>
            <div class="flex flex--center__vert flex--space__hor mar--bottom__1">
                <div class="input--label">Thumbnail</div>
                <AccentButton danger="true" title="Löschen" v-if="product.thumbnail || cachedThumb" @onClick="removeThumb"/>
            </div>
            <div class="flex flex--center width--100 pad--2" v-if="product.thumbnail">
                <img :src="`${$store.getters.URL}uploads/fetch/${product.thumbnail.thumbnail}?authentication=${token}`"
                     class="max--width__100pr height--max__60"/>
            </div>
            <div class="flex flex--center width--100 pad--2" v-if="!product.thumbnail && cachedThumb">
                <img src="#"
                     id="thumbnail"
                     class="max--width__100pr height--max__60"/>
            </div>
            <UploadImage @file-uploaded="(el) => cacheThumb(el)" :cached-thumb-name="cachedThumbName" v-if="!product.thumbnail && !cachedThumb"/>
            <label for="abo_link" class="input--label">Order Link</label>
            <input id="abo_link" class="input input--text mar--bottom__1"  v-model.trim="product.orderLink"/>
            <label for="abo_trailer" class="input--label">Trailer-ID</label>
            <input id="abo_trailer" class="input input--text mar--bottom__1"  v-model.trim="product.trailerVideoId"/>
            <label for="abo_id" class="input--label">Produkt-ID</label>
            <input id="abo_id" class="input input--text mar--bottom__2"  v-model.trim="product.externalId"/>
            <div class="flex flex--center__vert flex--space__hor mar--bottom__1">
                <div class="input--label">Produktbilder</div>
                <ActionButton icon="add" accent="true" @onClick="showUpload = true"/>
            </div>
            <div class="font--s14 mar--bottom__1" v-if="!product.uploads.length">
                Noch keine Produktbilder vorhanden.
            </div>
            <div class="flex flex--column bgColor--white b-radius--5 shadow--light pad--1 mar--bottom__1" v-for="(image, ind) in product.uploads" :key="ind">
                <div class="flex flex--space__hor flex--center__vert mar--top__1 mar--bottom__1">
                    <div class="font--s16 font--w700">
                        Bild {{ ind + 1}}
                    </div>
                    <AccentButton danger="true" title="Löschen" v-if="image" @onClick="removeImg(ind)"/>
                </div>
                <div class="flex flex--center width--100 pad--2" v-if="image">
                    <img :src="`${$store.getters.URL}uploads/fetch/${image.file}?authentication=${token}`"
                         class="max--width__100pr height--max__60"/>
                </div>
            </div>
            <div class="input--label mar--bottom__1" v-if="cachedThumbs.length">Neue Produktbilder</div>
            <div class="flex flex--column bgColor--white b-radius--5 shadow--light pad--1 mar--bottom__1" v-for="(image, ind) in cachedThumbs" :key="'new' + ind">
                <div class="flex flex--space__hor flex--center__vert mar--top__1 mar--bottom__1">
                    <div class="font--s16 font--w700">
                        Bild {{ ind + 1}}
                    </div>
                    <AccentButton danger="true" title="Löschen" v-if="image"/>
                </div>
                <div class="flex flex--center width--100 pad--2" v-if="image">
                    <img src="#"
                         :id="'img' + ind"
                         class="max--width__100pr height--max__60"/>
                </div>
            </div>
            <AccentButton title="Änderungen Speichern" @onClick="editSubscription"/>
        </div>
        <transition name="fade">
            <Modal title="Neues Produktbild" @toggle="showUpload = false" v-if="showUpload">
                <div class="pad--2 width--100 height--100">
                    <UploadImage @file-uploaded="cacheThumbs"/>
                </div>
            </Modal>
        </transition>
    </div>
</template>
<script>
    import ActionButton from "../../components/ActionButton";
    import UploadImage from "../../components/UploadImage";
    import AccentButton from "../../components/AccentButton";
    import _ from 'lodash';
    import Modal from "../../components/Modal";
    export default {
        name: 'EditAcademy',
        components: {Modal, AccentButton, UploadImage, ActionButton},
        data() {
            return {
                token: sessionStorage.getItem('DogU-AuthToken'),
                cachedThumb: null,
                cachedThumbName: null,
                cachedThumbs: [],
                cachedThumbNames: [],
                images: [null, null],
                product: {
                    uploads: [],
                },
                showUpload: false,
            };
        },
        computed: {
            subscription() {
                return this.$store.getters.product;
            },
        },
        methods: {
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('files', fileList[0], fileList[0].name);
                formData.append('type', 'productThumbnail');
                formData.append('product', this.product._id);
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
                const reader = new FileReader();
                reader.onload = (e) => {
                    document.getElementById('thumbnail').setAttribute('src', e.target.result);
                };
                reader.readAsDataURL(fileList[0]);
            },
            cacheThumbs(fileList) {
                const ind = this.cachedThumbs.length;
                const formData = new FormData();
                formData.append('files', fileList[0], fileList[0].name);
                formData.append('type', 'product');
                formData.append('product', this.product._id);
                this.cachedThumbs[ind] = formData;
                this.cachedThumbNames[ind] = fileList[0].name;
                this.showUpload = false;
                const reader = new FileReader();
                reader.onload = (e) => {
                    document.getElementById('img' + ind).setAttribute('src', e.target.result);
                };
                reader.readAsDataURL(fileList[0]);
                this.$forceUpdate();
                console.log(this.cachedThumbNames);
            },
            removeImg(ind) {
                this.product.uploads = this.product.uploads.filter((el, ind2) => ind2 !== ind);
            },
            removeThumb() {
                this.cachedThumb = null;
                this.product.thumbnail = null;
            },
            async editSubscription() {
                await this.$store.dispatch('updateSubscription', {
                    _id: this.product._id,
                    title: this.product.title,
                    externalId: this.product.externalId,
                    trailerVideoId: this.product.trailerVideoId,
                    price: this.product.price,
                    orderLink: this.product.orderLink,
                    uploads: this.product.uploads,
                    thumbnail: null,
                });
                await this.cachedThumbs.forEach(async (img) => {
                    await this.$store.dispatch('uploadImage', img);
                });
                if (this.cachedThumb) {
                    await this.$store.dispatch('uploadImage', this.cachedThumb);
                }
                this.$router.push('/courses');
            }
        },
        async mounted() {
            await this.$store.dispatch('retrieveSubscription');
            this.product = _.cloneDeep(this.subscription);
        }
    }
</script>
