<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/courses')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <div class="input--label mar--bottom__1" v-if="this.$route.params.id !== '0'">ID: {{ course._id }}</div>
            <div class="input--label">Kurs</div>
            <v-select :options="OKourses" label="title" :clearable="false" v-model="course" class="input--selection width--100 mar--bottom__2"/>
            <div class="flex flex--center width--100 pad--2" v-if="course && course.upload&& course.upload.file !== null && course.publish == null">
                <img :src="`${$store.getters.URL}uploads/fetch/${this.course.upload.file}?authentication=${token}`"
                     class="width--50"/>
            </div>
            <UploadImage @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName"/>
            <label for="course_title" class="input--label">Titel</label>
            <input id="course_title" class="input--text mar--bottom__2" v-model="title" :disabled="!course" />

            <div class="input--label">Light Kurs</div>
            <input id="ad_target"
                   type="checkbox"
                   :value="freeCourse"
                   @input="freeCourse = !freeCourse" class="input--checkbox pad--05"/>
            <label for="ad_target" class="flex flex--center pad--1">
              <div class="width--100 circle height--100 bgColor--primary"  v-if="freeCourse"/>
            </label>
            <label for="course_api" class="input--label">API Key</label>
            <input id="course_api" class="input--text mar--bottom__2" v-model="apiKey" :disabled="!freeCourse" />
            <label for="course_preview" class="input--label">Vorschau</label>
            <textarea id="course_preview" class="input--text height--10 mar--bottom__2" v-model="preview" :disabled="!course" />
            <label for="course_description" class="input--label">Beschreibung</label>
            <div>
                <quill-editor ref="myTextEditor"
                              id="course_description"
                              v-model="description"
                              :options="editorOptions"
                />
            </div>
            <label for="course_order" class="input--label">Order Link</label>
            <input id="course_order" class="input--text mar--bottom__2" v-model="orderLink" :disabled="!course" />
            <label for="course_trailer" class="input--label">Trailer</label>
            <input id="course_trailer" class="input--text mar--bottom__2" v-model="trailerId" :disabled="!course" />
            <label for="course_duration" class="input--label">Dauer</label>
            <input id="course_duration" class="input--text mar--bottom__2" v-model="duration" :disabled="!course" />
            <div class="input--label">Selling Points</div>
            <div class="flex"  v-for="(point, ind) in sellingPoints" :key="ind">
                <input class="input--text mar--bottom__1" :disabled="!course" v-model="sellingPoints[ind]"/>
                <div class="mar--left__1">
                    <ActionButton icon="close" @onClick="sellingPoints = sellingPoints.filter((el) => el !== point)" />
                </div>
            </div>
            <div class="flex flex--center mar--bottom__2">
                <ActionButton icon="add" accent="true" @onClick="!course ? null : sellingPoints.push('')"/>
            </div>
            <AccentButton :title="'Speichern'" @onClick="$route.params.id !== '0' ? updateCourse() : createCourse()"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';

    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import {quillEditor} from 'vue-quill-editor' // https://github.surmon.me/vue-quill-editor/
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    import UploadImage from "../../components/UploadImage";
    export default {
        name: 'CreateCourseView',
        components: {UploadImage, AccentButton, ActionButton, vSelect, quillEditor},
        data() {
            return {
                token: sessionStorage.getItem('DogU-AuthToken'),
                sellingPoints: [''],
                okourseID: null,
                title: null,
                preview: null,
                description: null,
                duration: null,
                orderLink: null,
                trailerId: null,
                freeCourse: false,
                apiKey: null,
                course: null,
                cachedThumb: null,
                cachedThumbName: null,
                editorOptions: {
                    modules: {
                        toolbar: [
                            ['bold', 'italic', 'underline', 'blockquote'],
                            [{'header': 1}, {'header': 2}],
                            [{'list': 'ordered'}, {'list': 'bullet'}],
                            ['align', {'align': 'center'}, {'align': 'right'}],
                            [{'script': 'sub'}, {'script': 'super'}],
                            ['clean', 'link', 'image'],
                        ]
                    }
                },
            };
        },
        computed: {
            courses() {
                return this.$store.getters.courses;
            },
            OKourses() {
                return this.$store.getters.OKourseCourses;
            },
        },
        methods: {
            removePoint(val) {
                this.sellingPoints = this.sellingPoints.filter((el) => el !== val);
            },
            async createCourse() {
              if (this.freeCourse && !this.apiKey) {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'Gib einen API Key ein',
                })
                return;
              }
                await this.$store.dispatch('createCourse', {
                    okourseCourseId: this.okourseID,
                    title: this.title,
                    shortDescription: this.preview,
                    description: this.description,
                    sellingPoints: this.sellingPoints,
                    duration: this.duration,
                    trailerVideoId: this.trailerId,
                    orderLink: this.orderLink,
                    freeCourse: this.freeCourse,
                    klicktippApiKey: this.freeCourse ? this.apiKey : undefined,
                }).then((id) => {
                    if (this.cachedThumb) {
                        this.uploadThumb(id).then(() => {
                            this.$router.go(-1);
                        });
                    } else {
                        this.$router.go(-1);
                    }
                });
            },
            async updateCourse() {
              if (this.freeCourse && !this.apiKey) {
                this.$store.commit('addToast', {
                  type: 'warning',
                  title: 'Gib einen API Key ein',
                })
                return;
              }
                await this.$store.dispatch('updateCourse', {
                    id: this.$route.params.id,
                    data: {
                        okourseCourseId: this.okourseID,
                        title: this.title,
                        shortDescription: this.preview,
                        description: this.description,
                        sellingPoints: this.sellingPoints,
                        duration: this.duration,
                        trailerVideoId: this.trailerId,
                        orderLink: this.orderLink,
                        freeCourse: this.freeCourse,
                        klicktippApiKey: this.freeCourse ? this.apiKey : undefined,
                    }
                }).then(() => {
                    if (this.cachedThumb) {
                        this.uploadThumb(this.$route.params.id).then(() => {
                            this.$router.go(-1);
                        });
                    } else {
                        this.$router.go(-1);
                    }
                });
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('files', fileList[0], fileList[0].name);
                formData.append('type', 'course');
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                this.cachedThumb.append('id', id);
                await this.$store.dispatch('uploadImage', this.cachedThumb);
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
        },
        watch: {
            course(newVal) {
                if (newVal !== null) {
                    console.log(newVal);
                    this.okourseID = newVal.okourseCourseId ? newVal.okourseCourseId : newVal._id;
                    this.preview = newVal.shortDescription;
                    this.description = newVal.description;
                    this.title = newVal.title;
                    this.duration = newVal.duration;
                    this.orderLink = newVal.orderLink;
                    this.trailerId = newVal.trailerVideoId;
                    this.freeCourse = newVal.freeCourse;
                    this.apiKey = newVal.klicktippApiKey;
                    this.sellingPoints = newVal.sellingPoints ? newVal.sellingPoints : [''];
                }
            },
        },
        mounted() {
            if (this.$route.params.id !== '0') {
               this.course = this.courses.find((el) => el._id === this.$route.params.id);
            }
        }
    };
</script>
