<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/knowledge')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <div class="flex flex--center width--100 pad--2" v-if="item && item.upload.file">
                <img :src="`${$store.getters.URL}uploads/fetch/${this.item.upload.file}?authentication=${token}`"
                     class="width--50"/>
            </div>
            <UploadImage @file-uploaded="cacheThumb" :cached-thumb-name="cachedThumbName"/>
            <div class="input--label">Typ</div>
            <v-select :options="options" label="title" :clearable="false" v-model="type" class="input--selection width--100 mar--bottom__2"/>
            <label for="knowledge_title" class="input--label">Titel</label>
            <input id="knowledge_title" class="input--text mar--bottom__2" v-model="title" :disabled="!type" />
            <label for="knowledge_description" class="input--label">Beschreibung</label>
            <textarea id="knowledge_description" class="input--text height--10 mar--bottom__2" v-model="description" :disabled="!type" />
            <label for="knowledge_link" class="input--label">Link</label>
            <input id="knowledge_link" class="input--text mar--bottom__2" v-model="link" :disabled="!type" />

            <div class="input--label">Interessen</div>
            <v-select :options="interests" label="value" :multiple="true" v-model="selectedInterests" class="input--selection width--100 mar--bottom__2"/>
            <div class="input--label">Tags</div>
          <div class="flex mar--bottom__1">
            <div v-for="(tag, ind) in tags" :key="tag + ind" class="pad--1 b-radius--5 bgColor--primary color--white font--s14 mar--right__1 flex flex--center__vert" @click="() => removeTag(tag)">
              {{ tag }}
              <ion-icon name="close" class="color--white font--s2 mar--left__1"/>
            </div>
          </div>
          <div class="flex">
            <input id="tag" class="input--text mar--bottom__2" v-model="tagText" :disabled="!type" />
            <div>
              <ActionButton icon="add" @onClick="addTag" />
            </div>
          </div>
            <AccentButton :title="'Speichern'" @onClick="$route.params.id !== '0' ? updateItem() : createItem()"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    import UploadImage from "../../components/UploadImage";
    import _ from 'lodash';
    export default {
        name: 'CreateKnowledgeItemView',
        components: {UploadImage, AccentButton, ActionButton, vSelect},
        data() {
            return {
                token: sessionStorage.getItem('DogU-AuthToken'),
                options: ['Video', 'Website'],
                type: null,
                title: null,
                description: null,
                link: null,
                item: null,
              tagText: '',
              selectedInterests: [],
              tags: [],

                cachedThumb: null,
                cachedThumbName: null,
            };
        },
        computed: {
            knowledge() {
                return this.$store.getters.knowledge;
            },
          interests() {
            return this.$store.getters.interests;
          },
        },
        methods: {
          addTag() {
            if (!this.tagText || this.tagText === '') return;
            if (this.tags.find((el) => el === this.tagText)) {
              this.tagText = '';
              return;
            }
            this.tags.push(this.tagText.toLowerCase());
            this.tagText = '';
          },
          removeTag(tag) {
            console.log(tag);
            if (this.tags.find((el) => el === tag)) {
              this.tags = this.tags.filter((el) => el !== tag);
            }
          },
            createItem() {
                this.$store.dispatch('createKnowledgeItem', {
                    title: this.title,
                    description: this.description,
                    url: this.link,
                    type: this.type ?? undefined,
                  interests: this.selectedInterests && this.selectedInterests.length ? this.selectedInterests.map((el) => el._id) : undefined,
                    tags: this.tags,
                }).then((id) => {
                    if (this.cachedThumb) {
                        this.uploadThumb(id).then(() => {
                            this.$router.go(-1);
                        });
                    }
                });
            },
            updateItem() {
                this.$store.dispatch('updateKnowledgeItem', {
                    id: this.$route.params.id,
                    data: {
                        title: this.title,
                        description: this.description,
                        url: this.link,
                        type: this.type ?? undefined,
                        interests: this.selectedInterests && this.selectedInterests.length ? this.selectedInterests.map((el) => el._id) : undefined,
                        tags: this.tags,
                    }
                }).then(() => {
                    if (this.cachedThumb) {
                        this.uploadThumb(this.$route.params.id).then(() => {
                            this.$router.go(-1);
                        });
                    }
                });
            },
            cacheThumb(fileList) {
                const formData = new FormData();
                formData.append('files', fileList[0], fileList[0].name);
                formData.append('type', 'article');
                this.cachedThumb = formData;
                this.cachedThumbName = fileList[0].name;
            },
            async uploadThumb(id) {
                this.cachedThumb.append('id', id);
                await this.$store.dispatch('uploadImage', this.cachedThumb);
                this.cachedThumb = null;
                this.cachedThumbName = null;
            },
        },
        watch: {
            item(newVal) {
                if (newVal !== null) {
                  console.log(newVal);
                    this.description = _.cloneDeep(newVal.description);
                    this.title = _.cloneDeep(newVal.title);
                    this.type = _.cloneDeep(newVal.type);
                    this.link = _.cloneDeep(newVal.url);
                    this.selectedInterests = _.cloneDeep(newVal.interests.map((el) => this.interests.find((interest) => interest._id === el)));
                    this.tags = _.cloneDeep(newVal.tags);
                }
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveKnowledgeItems');
            await this.$store.dispatch('retrieveInterests', {
              limit: 20,
              start: 0,
              attribute: 'value',
              asc: true,
              search: '',
            });
            if (this.$route.params.id !== '0') {
                this.item = this.knowledge.find((el) => el._id === this.$route.params.id);
            }
        }
    };
</script>

