<template>
  <div class="flex flex--column flex--center__vert width--100 height--100">
    <div class="dashboard--header">
      <ActionButton
        icon="arrow-back"
        @onClick="$router.push('/coop-partners')"
      />
    </div>
    <div
      class="dashboard--content pad--2 flex flex--column flex--grow height--100"
    >
      <div class="flex flex--center width--100 pad--2" v-if="logo && logo.file">
        <img
          :src="
            `${$store.getters.URL}uploads/fetch/${this.logo.file}?authentication=${token}`
          "
          class="width--50"
        />
      </div>
      <UploadImage
        @file-uploaded="cacheLogoThumb"
        :cached-thumb-name="cachedLogoThumbName"
        uploadText="Logo hochladen"
        v-if="!upload"
      />
      <div
        class="flex flex--center width--100 pad--2"
        v-if="headerImage && headerImage.file"
      >
        <img
          :src="
            `${$store.getters.URL}uploads/fetch/${this.headerImage.file}?authentication=${token}`
          "
          class="width--50"
        />
      </div>
      <UploadImage
        @file-uploaded="cacheHeaderThumb"
        :cached-thumb-name="cachedHeaderThumbName"
        uploadText="Headerbild hochladen"
        v-if="!upload"
      />
      <label for="coop_partner_title" class="input--label">Titel</label>
      <input
        id="coop_partner_title"
        class="input--text mar--bottom__2"
        v-model="title"
      />
      <label for="coop_partner_subtitle" class="input--label">Untertitel</label>
      <input
        id="coop_partner_subtitle"
        class="input--text mar--bottom__2"
        v-model="subtitle"
      />
      <label for="coop_partner_short_description" class="input--label"
        >Vorschau</label
      >
      <textarea
        id="coop_partner_short_description"
        class="input--text height--min__30 mar--bottom__2"
        v-model="shortDescription"
      />
      <label for="ad_description" class="input--label">Beschreibung</label>
      <div class="mar--bottom__2">
        <quill-editor
          ref="myTextEditor"
          id="course_description"
          v-model="details"
        />
      </div>

      <label for="ad__external_link" class="input--label">Externer Link</label>
      <input
        id="ad__external_link"
        class="input--text mar--bottom__2"
        v-model="link"
      />

      <div class="flex--column width--100 mar--bottom__2">
        <div class="input--label">Aktiv</div>
        <input
          id="isActive"
          type="checkbox"
          v-model="isActive"
          class="input--checkbox"
        />
        <label for="isActive" class="flex flex--center">
          <ion-icon name="checkmark" class="font--s3 color--primary" />
        </label>
      </div>

      <div
        class="flex"
        style="align-items: center; gap: .5rem; margin-bottom: 1rem;"
      >
        <div>
          <div class="flex--column width--100">
            <input
              id="coupons"
              type="checkbox"
              v-model="discountCoupons"
              style="width:2rem; height: 2rem;"
            />
            <label
              for="coupons"
              class="flex flex--center"
              style="width: 2rem; height: 2rem; padding: 0; margin: 0;"
            >
              <ion-icon name="checkmark" class="font--s3 color--primary" />
            </label>
          </div>
        </div>
        <div>
          <label for="ad_coupons" class="input--label">Gutscheine</label>
        </div>
      </div>

      <div v-if="discountCoupons">
        <table v-if="$route.params.id !== 'new'" class="mar--bottom__2">
          <tr class="color--grey">
            <td>Anzahl Codes</td>
            <td style="padding-left: 2rem;">Verwendet</td>
          </tr>
          <tr class="font--s14 font--w700">
            <td>{{ couponCounters.total }}</td>
            <td style="padding-left: 2rem;">{{ couponCounters.used }}</td>
          </tr>
        </table>

        <UploadCsv
          @file-uploaded="cacheCsvFile"
          :cached-thumb-name="cachedCsvFileName"
          uploadText="CSV hochladen"
        />
      </div>

      <AccentButton
        :title="'Speichern'"
        @onClick="save"
        class="mar--bottom__1"
        style="margin-top: 1rem;"
      />
      <div
        class="flex flex--end__hor"
        style="padding-bottom: 2rem;"
        v-if="$route.params.id !== 'new'"
      >
        <div>
          <!-- <AccentButton
            :danger="true"
            :title="'Löschen'"
            @onClick="onDelete"
            class="mar--bottom__2"
          /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import 'vue-select/dist/vue-select.css'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import { quillEditor } from 'vue-quill-editor' // https://github.surmon.me/vue-quill-editor/
import ActionButton from '@/components/ActionButton'
import AccentButton from '@/components/AccentButton'
import UploadImage from '@/components/UploadImage'
import UploadCsv from '@/components/UploadCsv'
export default {
  name: 'CreateCoopPartnerView',
  components: {
    UploadImage,
    UploadCsv,
    AccentButton,
    ActionButton,
    quillEditor,
  },
  data() {
    return {
      cachedLogoThumb: null,
      cachedLogoThumbName: null,
      cachedHeaderThumb: null,
      cachedHeaderThumbName: null,
      cachedCsvFile: null,
      cachedCsvFileName: null,
      token: sessionStorage.getItem('DogU-AuthToken'),
      // model
      title: null,
      subtitle: null,
      details: null,
      shortDescription: null,
      link: '',
      logo: null,
      headerImage: null,
      discountCoupons: false,
      // forMembersOnly
      isActive: false,
      //
      internal: false,
      deactivated: false,
      url: null,
      id: null,
      upload: null,
      selectedCommand: null,
      commands: [
        'openLexikon',
        'openActivities',
        'openPost',
        'openBadges',
        'openCourse',
        'openFreeCourse',
        'openForumPost',
        'openFeedingAdvice',
        'openPersonalityTest',
      ],
    }
  },
  computed: {
    OKourses() {
      return this.$store.getters.OKourseCourses
    },
    ads() {
      return this.$store.getters.ads
    },
    coopPartners() {
      return this.$store.getters.coopPartners
    },
    totalCoupons() {
      return this.$store.getters.totalCoupons
    },
    couponCounters() {
      return this.$store.getters.couponCounters
    },
  },
  methods: {
    toggleAdTarget() {
      this.internal = !this.internal
      console.log(this.internal)
    },
    toggleAdPublish() {
      this.publish = !this.publish
    },
    cacheCsvFile(fileList) {
      console.log(fileList)
      const formData = new FormData()
      formData.append('files', fileList[0], fileList[0].name)
      this.cachedCsvFile = formData
      this.cachedCsvFileName = fileList[0].name
    },
    cacheLogoThumb(fileList) {
      const formData = new FormData()
      formData.append('files', fileList[0], fileList[0].name)
      this.cachedLogoThumb = formData
      this.cachedLogoThumbName = fileList[0].name
    },
    cacheHeaderThumb(fileList) {
      const formData = new FormData()
      formData.append('files', fileList[0], fileList[0].name)
      this.cachedHeaderThumb = formData
      this.cachedHeaderThumbName = fileList[0].name
    },
    async onDelete() {
      if (this.$route.params.id !== 'new') {
        await this.$store.dispatch('deleteCoopPartner', this.$route.params.id)
        this.$router.push('/coop-partners')
      }
    },
    valid() {
      return this.title && this.details && this.link
    },
    async uploadImage(cachedFile) {
      if (cachedFile) {
        cachedFile.append('type', 'publicImage')
        try {
          const res = await this.$store.dispatch('uploadImage', cachedFile)
          return res.upload
        } catch (e) {
          console.log(e)
        }
      }
    },
    async save() {
      let success = true

      if (!this.valid()) {
        this.$store.commit('addToast', {
          type: 'warning',
          title: 'Bitte gib alle Informationen ein',
        })
        return
      }

      const data = {
        title: this.title,
        subtitle: this.subtitle,
        details: this.details,
        shortDescription: this.shortDescription,
        link: this.link,
        isActive: this.isActive,
        discountCoupons: this.discountCoupons,
      }

      if (this.cachedLogoThumb)
        data.logo = await this.uploadImage(this.cachedLogoThumb)

      if (this.cachedHeaderThumb)
        data.headerImage = await this.uploadImage(this.cachedHeaderThumb)

      if (this.$route.params.id !== 'new') {
        // UPDATE
        this.$store.dispatch('editCoopPartner', {
          id: this.$route.params.id,
          data,
        })

        if (this.discountCoupons && this.cachedCsvFile)
          this.$store.dispatch('uploadCouponsAsCsvFile', {
            id: this.$route.params.id,
            file: this.cachedCsvFile,
          })
      } else {
        // CREATE
        const addedPartner = await this.$store.dispatch('createCoopPartner', {
          data,
        })

        if (this.discountCoupons && this.cachedCsvFile)
          this.$store.dispatch('uploadCouponsAsCsvFile', {
            id: addedPartner._id,
            file: this.cachedCsvFile,
          })
      }
      if (success) this.$router.push('/coop-partners')
    },
  },
  async beforeMount() {
    await this.$store.dispatch('getAllCoopPartners')
    if (this.$route.params.id !== 'new') {
      await this.$store.dispatch('getAllCouponsForCoopPartner', {
        id: this.$route.params.id,
      })
      await this.$store.dispatch('getCouponsCountersForCoopPartner', {
        id: this.$route.params.id,
      })

      const coopPartner = this.coopPartners.find(
        el => el._id === this.$route.params.id,
      )
      console.log(coopPartner)
      this.title = coopPartner.title
      this.subtitle = coopPartner.subtitle
      this.details = coopPartner.details
      this.shortDescription = coopPartner.shortDescription
      this.link = coopPartner.link

      this.logo = coopPartner.logo
      this.headerImage = coopPartner.headerImage

      this.discountCoupons = coopPartner.discountCoupons
      this.isActive = coopPartner.isActive

      //this.upload = coopPartner.uploads[0]
      //this.publish = coopPartner.publish
      //this.deactivated = coopPartner.deactivated
      //this.internal = !!coopPartner.openInternal
      //   this.selectedCommand =
      //     coopPartner.openInternal != null
      //       ? coopPartner.openInternal.command
      //       : null
      //   this.id =
      //     coopPartner.openInternal != null
      //       ? coopPartner.openInternal.command === 'openCourse' ||
      //         coopPartner.openInternal.command === 'openFreeCourse'
      //         ? coopPartner.openInternal.courseId
      //         : coopPartner.openInternal.command === 'openPost' ||
      //           coopPartner.openInternal.command === 'openForumPost'
      //         ? coopPartner.openInternal.postId
      //         : coopPartner.openInternal.command === 'openProfile'
      //         ? coopPartner.openInternal.userId
      //         : null
      //       : null
      //   this.url = coopPartner.openExternal ? coopPartner.openExternal.url : null
    }
  },
}
</script>
