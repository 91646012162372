<template>
    <div class="flex flex--column width--100">
        <div class="width--100 grid dashboard--split height--100">
            <div class="width--100 height--100 flex--column">
                <div class="pad--2">
                    <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                        <div class="font--s2 font--w700">
                            Kategorien
                        </div>
                        <div class="mar--left__1">
                            <ActionButton icon="add" accent="true" @onClick="$router.push('/knowledge/category/0')"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--column list overflowAuto height--80__abs">
                    <KnowledgeCategoryTile v-for="category in categories" :key="category._id"
                                           :category="category" :draggedEl="draggedEl"
                                           @dragEvent="(val) => handleDragEvent(val)" @dragEl="handleDragEl"
                                           @removeItem="handleRemoveItem" />
                </div>
            </div>
            <div class="width--100 height--100 flex--column overflowAuto">
                <div class="pad--2">
                    <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
                        <div class="font--s2 font--w700">
                            Alle Elemente
                        </div>
                        <div class="mar--left__1">
                            <ActionButton icon="add" accent="true" @onClick="$router.push('/knowledge/item/0')"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--column list overflowAuto height--80__abs">
                    <KnowledgeItemTile v-for="item in knowledge" :key="item._id"
                                       :item="item" @dragEl="handleDragEl" @deleteItem="handleDeleteItem(item._id)" />

                </div>
            </div>
        </div>
        <div class="width--100 pad--1" v-if="changes">
            <AccentButton title="Änderungen Speichern" @onClick="updateCategories"/>
        </div>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';
    import {reorderArray} from '@/services/helpers';
    import KnowledgeItemTile from "../../components/KnowledgeItemTile";
    import KnowledgeCategoryTile from "../../components/KnowledgeCatgeoryTile";
    import _ from 'lodash';
    import AccentButton from "../../components/AccentButton";

    export default {
        name: 'KnowledgeView',
        components: {AccentButton, KnowledgeCategoryTile, KnowledgeItemTile, ActionButton},
        data() {
            return {
                draggedEl: null,
                knowledge: [],
                categories: [],
                changes: false,
            };
        },
        computed: {
            cachedKnowledge: {
                get() {
                    return this.$store.getters.knowledge;
                },
                set(val) {
                    this.$store.commit('setKnowledge', val);
                }
            },
            cachedCategories: {
                get() {
                    return this.$store.getters.knowledgeCategories;
                },
                set(val) {
                    this.$store.commit('setKnowledgeCategories', val);
                }
            },
        },
        methods: {
            async handleDeleteItem(id) {
                this.categories.forEach((el) => {
                    el.articles = el.articles.filter(el2 => el2._id !== id);
                });
                await this.updateCategories();
                await this.$store.dispatch('deleteKnowledgeItem', id);
            },
            handleDragEvent(data) {
                if (data.type === 'reorder') {
                    const oldInd = this.categories.findIndex((el) => el._id === this.draggedEl.split('-')[1]);
                    const newInd = this.categories.findIndex((el) => el._id === data.to.split('-')[1]);
                    this.categories = reorderArray(this.categories, oldInd, newInd);
                    this.changes = true;
                } else {
                    const ind = this.categories.findIndex((el) => el._id === data.to.split('-')[1]);
                    if (this.categories[ind].articles.find((el) => el._id === this.draggedEl.split('-')[1]) == null) {
                        if (this.categories[ind].type === 'Highlight' && this.categories[ind].articles.length) {
                            return;
                        }
                        this.categories[ind].articles.push((this.knowledge.find((el) => el._id === this.draggedEl.split('-')[1])));
                    }
                    console.log(this.categories);
                    this.changes = true;
                }
            },
            handleDragEl(val) {
                this.draggedEl = val;
            },
            handleRemoveItem(data) {
                const ind = this.categories.findIndex((el) => el._id === data.category);
                this.categories[ind].articles = this.categories[ind].articles.filter((el) => el._id !== data.item);
                this.changes = true;
            },
            async updateCategories() {
                for (let i = 0; i < this.categories.length; i++) {
                    const oldInd = this.cachedCategories.findIndex((el) => el._id === this.categories[i]._id);
                    /** if ((i !== oldInd) || (this.categories[i].articles.map(el => el._id) !== this.cachedCategories[oldInd].articles.map(el => el._id))) {
                        await this.$store.dispatch('updateKnowledgeCategory', {
                            id: this.categories[i]._id,
                            data: {
                                position: i !== oldInd ? i : undefined,
                                articles: this.categories[i].articles.map(el => el._id) !== this.cachedCategories[oldInd].articles.map(el => el._id) ? this.categories[i].articles.map(el => el._id) : undefined,
                            },
                        })
                    } **/
                    await this.$store.dispatch('updateKnowledgeCategory', {
                      id: this.categories[i]._id,
                      data: {
                        position: i,
                        articles: this.categories[i].articles.map(el => el._id) !== this.cachedCategories[oldInd].articles.map(el => el._id) ? this.categories[i].articles.map(el => el._id) : undefined,
                      },
                    })
                }
                this.$store.dispatch('retrieveKnowledgeCategories');
                this.changes = false;
            },
        },
        watch: {
            cachedKnowledge(newVal) {
                this.knowledge = _.cloneDeep(newVal);
            },
            cachedCategories(newVal) {
                this.categories = _.cloneDeep(newVal).sort((a, b) => a.position < b.position);
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveKnowledgeItems');
            await this.$store.dispatch('retrieveKnowledgeCategories');
            this.knowledge = _.cloneDeep(this.cachedKnowledge);
            this.categories = _.cloneDeep(this.cachedCategories).sort((a, b) => a.position < b.position);
        }
    }
</script>
