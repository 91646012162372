<template>
    <div class="flex flex--column flex--center__vert">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/courses')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column">
            <label for="course_title" class="input--label">Titel</label>
            <input id="course_title" class="input--text mar--bottom__2" v-model="title"/>
            <label for="course_title" class="input--label">Typ</label>
            <v-select :options="options" :clearable="false" v-model="type" class="input--selection width--100 mar--bottom__2"/>
            <AccentButton title="Hinzufügen" @onClick="createCategory"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    export default {
        name: 'CreateCategoryView',
        components: {AccentButton, ActionButton, vSelect},
        data() {
            return {
                options: ['Highlight', 'List', 'Carousel', 'Grid'],
                type: 'Highlight',
                title: '',
            };
        },
        methods: {
            createCategory() {
                if (this.title && this.title.trim() !== '') {
                    this.$store.dispatch('createCourseCategory', {
                        title: this.title,
                        type: this.type,
                        position: 0,
                        courses: [],
                    });
                    this.$router.go(-1);
                } else {
                    this.$store.commit('addToast', {
                        type: 'warning',
                        title: 'Bitte gib einen Titel ein',
                    });
                }
            }
        }
    };
</script>
