<template>
    <div class="height--100__abs width--100 flex flex--center">
        <LoginBox />
    </div>
</template>
<script>
    import LoginBox from '@/components/LoginBox';
    export default {
        name: 'AuthenticationView',
        components: {LoginBox}
    }
</script>
