<template>
  <div class="width--100 height--100 flex--column">
    <div class="pad--2 sticky top--0 front">
      <div
        class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5"
      >
        <div style="margin-left: auto;">
          <ActionButton
            icon="add"
            accent="true"
            @onClick="$router.push('/coop-partners/new')"
          />
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div
        class="flex flex--center width--100 height--50"
        key="loader"
        v-if="isLoading"
      >
        <loader :loading="true" color="#779d2b" size="50px" />
      </div>
      <div class="flex flex--column list overflowAuto" key="list" v-else>
        <transition-group name="list">
          <div
            class="width--100 pad--1 flex flex--center font--w700 font--s16"
            :key="'results'"
          >
            {{ listCount + ' Ergebnisse' }}
          </div>
          <div
            v-for="partner in coopPartners"
            :key="partner._id"
            class="list--entry"
            @click="$router.push(`/coop-partners/${partner._id}`)"
          >
            <div
              class="width--100 height--100 grid list--entry__grid6 font--s14"
            >
              <div
                class="flex flex--column flex--center__hor pad--1 lineHeight--1"
                style="padding-left: 2rem;"
              >
                <div
                  class="font--s1 color--grey"
                  style="width: 50px; height: 50px; display: flex; justify-content: center; align-items: center; border-radius: 50%; border: 2px solid #eaeaea; overflow: hidden; background-color: #fff;"
                >
                  <img
                    v-if="partner.logo && partner.logo.thumbnail"
                    :src="
                      `${$store.getters.URL}uploads/fetch/${partner.logo.thumbnail}?authentication=${token}`
                    "
                    style="max-width: 100%;"
                  />
                </div>
              </div>
              <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
                style="flex: 1"
              >
                <div class="font--s1 color--grey">
                  Partnername
                </div>
                <div class="font--w700">
                  {{ partner.title }}
                </div>
              </div>
              <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Vorshau
                </div>
                <div class="font--w700">
                  {{ partner.subtitle }}
                </div>
              </div>

              <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Beitritt
                </div>
                <div class="font--w700">
                  {{ moment(partner.createdAt).format('DD.MM.YYYY') }}
                </div>
              </div>

              <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Aktiv
                </div>
                <div class="font--w700">
                  {{ partner.isActive ? 'Ja' : 'Nein' }}
                </div>
              </div>

              <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Mitglieder
                </div>
                <div class="font--w700">
                  {{
                    isHanseMerkur(partner) && hanseMerkurCoopRecordsTotal
                      ? hanseMerkurCoopRecordsTotal
                      : getMemberCount(partner)
                  }}
                </div>
              </div>

              <!-- <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Veröffentlicht
                </div>
                <div class="font--w700">
                  {{
                    ad.openExternal
                      ? 'Externer Link'
                      : ad.openInternal
                      ? 'Interner Link'
                      : 'Kein Link'
                  }}
                </div>
              </div> -->
              <!-- <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Veröffentlicht
                </div>
                <div class="font--w700">
                  {{
                    ad.openExternal
                      ? ad.openExternal.url
                      : ad.openInternal
                      ? ad.openInternal.type
                      : '-'
                  }}
                </div>
              </div> -->
              <!-- <div
                class="flex flex--column flex--center__hor pad--2 lineHeight--1"
              >
                <div class="font--s1 color--grey">
                  Bearbeitet
                </div>
                <div class="font--w700">
                  {{ moment(ad.modifiedAt).format('DD.MM.YYYY') }}
                </div>
              </div> -->
              <div class="flex flex--center">
                <ActionButton icon="pencil" accent="true" />
              </div>
            </div>
          </div>
          <div class="width--100 pad--2 flex flex--center" :key="'footer'">
            <!-- <ActionButton
              icon="chevron-back"
              class="mar--right__1"
              @onClick="pageNavigation"
            />
            <div class="font--w700">
              {{ (page - 1) * 50 }} bis {{ page * 50 }}
            </div>
            <ActionButton
              icon="chevron-forward"
              class="mar--left__1"
              @onClick="pageNavigation(true)"
            /> -->
          </div>
        </transition-group>
      </div>
    </transition>
  </div>
</template>
<script>
import ActionButton from '@/components/ActionButton'
import { HANSE_MERKUR } from '../../consts'
export default {
  name: 'CoopPartnersOverview',
  components: { ActionButton },
  data() {
    return {
      token: sessionStorage.getItem('DogU-AuthToken'),
      options: ['Offen', 'Geschlossen'],
      entries: [1, 2, 3, 4],
      list: 'Offen',

      posX: null,
      posY: null,

      showMainPopup: false,
      isLoading: false,
      page: 1,

      searchString: null,
    }
  },
  methods: {
    isHanseMerkur(partner) {
      return partner._id === HANSE_MERKUR
    },
    getMemberCount(partner) {
      const countArray = this.$store.getters.memberCount
      if (!countArray) return '0 *'
      const foundCount = countArray.find(p => p._id === partner._id)
      return foundCount ? foundCount.count : '0 *'
    },
    toggleMainPopup(e) {
      this.posX = e.clientX
      this.posY = e.clientY
      this.showMainPopup = !this.showMainPopup
    },
    async sort({ attribute, asc }) {
      if (this.list === 'Offen') {
        await this.$store.dispatch('retrieveAds', {
          limit: this.page * 50,
          skip: this.page * 50 - 50,
          attribute,
          asc,
        })
      } else {
        await this.$store.dispatch('retrieveAds', {
          limit: this.page * 50,
          skip: this.page * 50 - 50,
          attribute,
          asc,
        })
      }
    },
    async pageNavigation(forward) {
      if (forward) {
        if (this.page * 50 < this.listCount) {
          this.isLoading = true
          this.page++
          await this.$store.dispatch('retrieveAds', {
            limit: this.page * 50,
            skip: this.page * 50 - 50,
            search: this.search,
          })
          await setTimeout(() => {
            this.isLoading = false
          }, 1000)
        }
      } else {
        if (this.page > 1) {
          this.isLoading = true
          this.page--
          await this.$store.dispatch('retrieveAds', {
            limit: this.page * 50,
            skip: this.page * 50 - 50,
            search: this.search,
          })
          await setTimeout(() => {
            this.isLoading = false
          }, 1000)
        }
      }
    },
  },
  computed: {
    coopPartners: {
      get() {
        return this.$store.getters.coopPartners
      },
      set(val) {
        this.$store.commit('setCoopPartners', val)
      },
    },
    listCount() {
      console.log(this.$store.getters.totalCoopPartners)
      if (this.$store.getters.totalCoopPartners > 0) {
        return this.$store.getters.totalCoopPartners
      }
      return 'Keine '
    },
    authtoken() {
      return sessionStorage.getItem('DogU-AuthToken')
    },
    hanseMerkurCoopRecordsTotal() {
      return this.$store.getters.hanseMerkurCoopRecordsTotal
    },
  },
  watch: {
    async list(newVal) {
      this.searchString = null
      this.isLoading = true
      await this.$store.dispatch('retrieveAds', {
        limit: 50,
        skip: 0,
        search: newVal,
      })
      setTimeout(() => {
        this.isLoading = false
      }, 1000)
    },
  },
  async mounted() {
    if (!this.coopPartners || this.coopPartners.isEmpty) {
      this.isLoading = true
      await this.$store.dispatch('getAllCoopPartners')
      await this.$store.dispatch('getHanseMerkurCoopRecordsTotal')

      await setTimeout(() => {
        this.isLoading = false
      }, 1000)
    } else {
      await this.$store.dispatch('getAllCoopPartners')
      await this.$store.dispatch('getHanseMerkurCoopRecordsTotal')

      for (let i = 0; i < this.coopPartners.length; i++) {
        if (this.coopPartners[i]._id !== HANSE_MERKUR) {
          await this.$store.dispatch('getMemberCount', {
            id: this.coopPartners[i]._id,
          })
        }
      }
    }
  },
}
</script>
