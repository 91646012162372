import LandingView from '@/views/LandingView'
import OverviewView from '@/views/2_Overview/OverviewView'
import ReportsView from '@/views/3_Reports/ReportsView'
import MarkerView from '@/views/4_Marker/MarkerView'
// import MarkerDetails from '@/views/4_Marker/MarkerDetails';
import ReportDetails from '@/views/3_Reports/ReportDetails'
// import OverviewDetails from '@/views/2_Overview/OverviewDetails';
import CoursesView from '@/views/1_Courses/CoursesView'
import CreateCourseView from '@/views/1_Courses/CreateCourseView'
import CreateCategoryView from '@/views/1_Courses/CreateCategoryView'
import OverviewCreate from '@/views/2_Overview/OverviewCreate'
import CreateMarker from '@/views/4_Marker/CreateMarker'
import KnowledgeView from '../views/6_Knowledge/KnowledgeView'
import CreateKnowledgeItemView from '../views/6_Knowledge/CreateKnowledgeItemView'
import CreateKnowledgeCatgeoryView from '../views/6_Knowledge/CreateKnowledgeCatgeoryView'
import NotificationView from '../views/5_Notifications/NotificationView'
import CreateNotificationView from '../views/5_Notifications/CreateNotificationView'
import EditAcademy from '../views/1_Courses/EditAcademy'
import AdsOverview from '../views/7_Ads/AdsOverview'
import CreateAdView from '../views/7_Ads/CreateAdView'
import CoopPartnersView from '@/views/8_Coop_Partners/CoopPartnersView'
import CreateCoopPartnerView from '@/views/8_Coop_Partners/CreateCoopPartnerView'

export default [
  {
    path: '/',
    component: LandingView,
  },
  {
    path: '/notifications',
    component: NotificationView,
  },
  {
    path: '/notifications/new',
    component: CreateNotificationView,
  },
  {
    path: '/ads',
    component: AdsOverview,
  },
  {
    path: '/ads/:id',
    component: CreateAdView,
  },
  {
    path: '/knowledge',
    component: KnowledgeView,
  },
  {
    path: '/knowledge/item/:id',
    component: CreateKnowledgeItemView,
  },
  {
    path: '/knowledge/category/:id',
    component: CreateKnowledgeCatgeoryView,
  },
  {
    path: '/courses',
    component: CoursesView,
  },
  {
    path: '/courses/academy',
    component: EditAcademy,
  },
  {
    path: '/courses/course/:id',
    component: CreateCourseView,
  },
  {
    path: '/courses/category/:id',
    component: CreateCategoryView,
  },
  {
    path: '/overview',
    component: OverviewView,
  },
  {
    path: '/overview/:type/:id',
    component: OverviewCreate,
  },
  {
    path: '/coop-partners',
    component: CoopPartnersView,
  },
  {
    path: '/coop-partners/:id',
    component: CreateCoopPartnerView,
  },
  {
    path: '/reports',
    component: ReportsView,
  },
  {
    path: '/reports/:type/:id',
    component: ReportDetails,
  },
  {
    path: '/marker',
    component: MarkerView,
  },
  {
    path: '/marker/:type/:id',
    component: CreateMarker,
  },
  {
    path: '/functions',
  },
  {
    path: '/settings',
  },
]
