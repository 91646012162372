<template>
    <div class="width--100 height--100 flex--column">
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: 1,
                    })">
                        A - Z
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: -1,
                    })">
                        Z - A
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
                        Neueste Zuerst
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
                        Älteste Zuerst
                    </div>
                </PopUp>
            </div>
        </transition>
        <div class="pad--2">
            <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
                <input type="text" class="input--text" placeholder="Suchen..." v-model="searchString"/>
                <div class="mar--left__1">
                    <ActionButton icon="swap-vertical" light="true" @onClick="toggleMainPopup"
                                  v-click-outside="() => { this.showMainPopup = false }"/>
                </div>
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/notifications/new')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#779d2b" size="50px" />
            </div>
            <div class="flex flex--column list" v-if="!isLoading" key="list">
                <transition-group name="list">
                    <div class="width--100 pad--1 flex flex--center font--w700 font--s16"
                         :key="'results'">
                        <div>
                            {{ totalNotifications + ' Ergebnisse' }}
                        </div>
                    </div>
                    <div v-for="notification in notifications" :key="notification._id" class="list--entry">
                        <div class="width--100 height--100 grid list--entry__grid4 font--s14">
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Typ
                                </div>
                                <div class="font--w700">
                                    {{ notification.type }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Titel
                                </div>
                                <div class="font--w700">
                                    {{ notification.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Nutzer erreicht
                                </div>
                                <div class="font--w700">
                                    {{ notification.reachedUserCount }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Erstellungsdatum
                                </div>
                                <div class="font--w700">
                                    {{ moment(notification.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--center">
                                <ActionButton icon="pencil" accent="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50}} bis {{ page * 50}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import ActionButton from '@/components/ActionButton';
    import PopUp from "../../components/PopUp";

    export default {
        name: 'NotificationView',
        components: {PopUp, ActionButton},
        data() {
            return {
                page: 1,
                posX: null,
                posY: null,
                showMainPopup: false,
                searchString: '',
                isLoading: false,
            };
        },
        computed: {
            notifications() {
                /* eslint-disable */
                console.log(this.$store.getters.notifications);
                return this.$store.getters.notifications;
            },
            totalNotifications() {
                return this.$store.getters.totalNotifications;
            },
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            async sort({ attribute, asc }) {
                await this.$store.dispatch('retrieveNotifications', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    attribute,
                    asc,
                    search: this.searchString,
                });
            },
        },
        watch: {
            async searchString() {
                this.isLoading = true;
                this.page = 1;
                await this.$store.dispatch('retrieveNotifications', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    search: this.searchString,
                });
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
        },
        async mounted() {
            if (!this.notifications || !this.notifications.length) {
                this.isLoading = true;
                await this.$store.dispatch('retrieveNotifications', {
                    amount: 50,
                    skip: (this.page * 50) - 50,
                });
                await setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            } else {
                await this.$store.dispatch('retrieveNotifications', {
                    amount: 50,
                    skip: (this.page * 50) - 50,
                });
            }
        }
    }
</script>
