<template>
  <div id="app">
    <MainLoader />
    <transition-group
            name="fade"
            v-if="toasts.length"
            class="toast--container fixed bottom--2 right--2 flex flex--column flex--end__hor">
      <toast v-for="toast in toasts"
             :key="toast.id"
             :toastID="toast.id"
             :type="toast.type"
             :title="toast.title"/>
    </transition-group>
  </div>
</template>

<script>
import MainLoader from '@/views/MainLoader';
import Toast from "./components/Toast";
export default {
  name: 'App',
  components: {Toast, MainLoader},
  computed: {
    toasts() {
      return this.$store.getters.toasts;
    },
  },
}
</script>

<style lang="scss">
  @import "style/main";
</style>
