<template>
    <div class="popup--container b-radius--5 absolute flex flex--column overflowHidden" :style="{'top': `${posY + 30}px`, 'left': `${posX - 250}px`}">
        <slot />
    </div>
</template>
<script>
    export default {
        name: 'PopUp',
        props: ['posX', 'posY']
    };
</script>
