<template>
    <div class="width--100 height--100 flex--column">
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'type',
                        asc: 1,
                    })">
                        A - Z
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'type',
                        asc: -1,
                    })">
                        Z - A
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
                        Neueste Zuerst
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
                        Älteste Zuerst
                    </div>
                </PopUp>
            </div>
        </transition>
        <div class="pad--2 sticky top--0 front">
            <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
                <v-select :options="options" :clearable="false" v-model="list" class="input--selection width--20 mar--right__1"/>
                <input type="text" class="input--text" placeholder="Suchen..." v-model="searchString"/>
                <div class="mar--left__1">
                    <ActionButton icon="swap-vertical" accent="true" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#779d2b" size="50px" />
            </div>
            <div class="flex flex--column list overflowAuto" key="list" v-else>
                <transition-group name="list">
                    <div class="width--100 pad--1 flex flex--center font--w700 font--s16" :key="'results'">
                        {{ listCount + ' Ergebnisse' }}
                    </div>
                    <div v-for="report in reports" :key="report._id" class="list--entry" @click="$router.push(`/reports/${report.type}/${report._id}`)">
                        <div class="width--100 height--100 grid list--entry__grid5 font--s14">
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Typ
                                </div>
                                <div class="font--w700">
                                    {{ report.type === 'user' ? 'Nutzer' : report.type === 'post' ? 'Post' : report.type === 'comment' ? 'Kommentar' : report.type === 'event' ? 'Event' : report.type === 'marker' ? 'Markierung' : 'Unbekannt' }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="report.type === 'user'">
                                <div class="font--s1 color--grey">
                                    Gemeldeter Nutzer
                                </div>
                                <div class="font--w700" v-if="report.user">
                                    {{ report.user.firstName }} {{ report.user.lastName}}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="report.type === 'event'">
                              <div class="font--s1 color--grey">
                                Gemeldetes Event
                              </div>
                              <div class="font--w700">
                                {{ report.event.title }}
                              </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="report.type === 'comment'">
                                <div class="font--s1 color--grey">
                                    Gemeldeter Kommentar
                                </div>
                                <div class="font--w700" v-if="report.comment">
                                    {{ report.comment && report.comment.message && report.comment.message.length > 25 ? `${report.comment.message.slice(0, 25)}...` : report.comment.message }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="report.type === 'post'">
                                <div class="font--s1 color--grey">
                                    Gemeldeter Post
                                </div>
                                <div class="font--w700">
                                    {{ report.post && report.post.title && report.post.title.length > 25 ? `${report.post.title.slice(0, 25)}...` : report.post.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Gemeldet von
                                </div>
                                <div class="font--w700">
                                    {{ report.reportedByUsers.length }} {{ report.reportedByUsers.length > 1 ? 'Nutzern' : 'Nutzer'}}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Grund
                                </div>
                                <div class="font--w700">
                                    {{ report.reason === 'spam' ? 'Spam' : report.reason === 'old' ? 'Veraltet' : 'Unangebrachtes Verhalten' }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Datum
                                </div>
                                <div class="font--w700">
                                    {{ moment(report.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--center">
                                <ActionButton icon="pencil" accent="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageNavigation"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50}} bis {{ page * 50}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageNavigation(true)"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import PopUp from "../../components/PopUp";
    export default {
        name: 'ReportsView',
        components: {PopUp, ActionButton, vSelect},
        data() {
            return {
                options: ['Offen','Geschlossen'],
                entries: [1,2,3,4],
                list: 'Offen',

                posX: null,
                posY: null,

                showMainPopup: false,
                isLoading: false,
                page: 1,

                searchString: null,
            };
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            async sort({ attribute, asc }) {
                if (this.list === 'Offen') {
                    await this.$store.dispatch('retrieveAllReports', {
                        limit: this.page * 50,
                        skip: (this.page * 50) - 50,
                        attribute,
                        asc,
                        status: 'open',
                    });
                } else {
                    await this.$store.dispatch('retrieveAllReports', {
                        limit: this.page * 50,
                        skip: (this.page * 50) - 50,
                        attribute,
                        asc,
                        status: 'closed',
                    });
                }
            },
            async pageNavigation(forward) {
              if (forward) {
                  if (this.page * 50 < this.listCount) {
                      this.isLoading = true;
                      this.page++;
                      if (this.list === 'Offen') {
                          await this.$store.dispatch('retrieveAllReports', {
                              limit: this.page * 50,
                              skip: (this.page * 50) - 50,
                              status: 'open',
                          });
                      } else {
                          await this.$store.dispatch('retrieveAllReports', {
                              limit: this.page * 50,
                              skip: (this.page * 50) - 50,
                              status: 'closed',
                          });
                      }
                      await setTimeout(() => {
                          this.isLoading = false;
                      }, 1000);
                  }
              }  else {
                  if (this.page > 1) {
                      this.isLoading = true;
                      this.page--;
                      if (this.list === 'Offen') {
                          await this.$store.dispatch('retrieveAllReports', {
                              limit: this.page * 50,
                              skip: (this.page * 50) - 50,
                              status: 'open',
                          });
                      } else {
                          await this.$store.dispatch('retrieveAllReports', {
                              limit: this.page * 50,
                              skip: (this.page * 50) - 50,
                              status: 'closed',
                          });
                      }
                      await setTimeout(() => {
                          this.isLoading = false;
                      }, 1000);
                  }
              }
            },
        },
        computed: {
            reports: {
                get() {
                    return this.$store.getters.reports;
                },
                set(val) {
                    this.$store.commit('setReports', val);
                }
            },
            listCount() {
                if (this.$store.getters.totalReports > 0) {
                    return this.$store.getters.totalReports;
                }
                return 'Keine ';
            }
        },
        watch: {
            async list(newVal) {
                this.searchString = null;
                this.isLoading = true;
                switch (newVal) {
                    case 'Offen':
                        await this.$store.dispatch('retrieveAllReports', {
                            limit: 50,
                            skip: 0,
                            status: 'open',
                        });
                        break;
                    case 'Geschlossen':
                        await this.$store.dispatch('retrieveAllReports', {
                            limit: 50,
                            skip: 0,
                            status: 'closed',
                        });
                        break;
                    default:
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            async searchString(newVal) {
                this.isLoading = true;
                switch (this.list) {
                    case 'Offen':
                        await this.$store.dispatch('retrieveAllReports', {
                            limit: 50,
                            skip: 0,
                            status: 'open',
                            search: newVal,
                        });
                        break;
                    case 'Geschlossen':
                        await this.$store.dispatch('retrieveAllReports', {
                            limit: 50,
                            skip: 0,
                            status: 'closed',
                            search: newVal,
                        });
                        break;
                    default:
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            }
        },
        async mounted() {
            if (!this.reports || this.reports.isEmpty) {
                this.isLoading = true;
                await this.$store.dispatch('retrieveAllReports', {
                    limit: 50,
                    skip: 0,
                    status: 'open',
                });
                await setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            } else {
                await this.$store.dispatch('retrieveAllReports', {
                    limit: 50,
                    skip: 0,
                    status: 'open',
                });
            }
        }
    }
</script>
