<template>
    <div class="modal fixed flex flex--center">
        <div class="modal--backdrop fixed opacity--5 flex flex--center" @click="$emit('toggle')" />
        <div class="modal--container b-radius--5 flex flex--column">
            <div class="modal--header pad--2 flex flex--center__vert flex--space__hor  border--bottom__light">
                <div class="font--s2 font--w700">
                    {{ title }}
                </div>
                <i class="ion-md-close clickable font--s3 lineHeight--1" @click="$emit('toggle')" />
            </div>
            <div class="width--100 height--100 flex--grow flex overflowAuto">
                <slot />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Modal',
        props: ['title'],
    };
</script>
