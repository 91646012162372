const BASE_URL =
  process.env.VUE_APP_API_BASE_URL || 'https://api.doguniversity.app'

export default {
  URL: `${BASE_URL}/v1/`,
  authenticated: false,
  toasts: [],
  users: [],
  totalUsers: 0,
  posts: [],
  totalPosts: 0,
  dogs: [],
  totalDogs: 0,
  dogBreeds: [],
  totalDogBreeds: 0,
  forumCategories: [],
  totalForumCategories: 0,
  interests: [],
  totalInterests: 0,
  reports: [],
  totalReports: [],
  markers: [],
  totalMarkers: 0,
  vaccines: [],
  totalVaccines: 0,
  announcements: [],
  totalAnnouncements: 0,
  skills: [],
  totalSkills: 0,
  detailedSkill: null,
  OKourseCourses: [],
  knowledge: [],
  knowledgeCategories: [],
  courses: [],
  courseCategories: [],
  notifications: [],
  totalNotifications: 0,
  ads: [],
  totalAds: 0,
  product: null,
  coopPartners: [],
  totalCoopPartners: 0,
  coupons: [],
  totalCoupons: 0,
  coopRecords: [],
  coopCoupons: [],
  couponCounters: { total: 0, used: 0 },
  hanseMerkurCoopRecordsTotal: 0,
  memberCount: [],
}
