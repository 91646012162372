<template>
  <div class="flex flex--column flex--center__vert width--100 height--100">
    <div class="dashboard--header">
      <ActionButton icon="arrow-back" @onClick="$router.push('/overview')" />
    </div>
    <div class="dashboard--content pad--2 flex flex--column flex--grow">
      <div class="input--label">Typ</div>
      <v-select
        :options="options"
        :clearable="false"
        v-model="selection"
        :disabled="$route.params.type !== 'new'"
        class="input--selection width--100 mar--bottom__2"
      />
      <div class="flex--column width--100" v-if="selection === 'Hunderasse'">
        <label for="dogbreed" class="input--label">Name</label>
        <input
          id="dogbreed"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <div
          class="flex flex--center width--100 pad--2"
          v-if="content && content.upload.file"
        >
          <img
            :src="
              `${$store.getters.URL}uploads/fetch/${this.content.upload.file}?authentication=${token}`
            "
            class="width--50"
          />
        </div>
        <UploadImage
          @file-uploaded="cacheThumb"
          :cached-thumb-name="cachedThumbName"
        />
      </div>
      <div
        class="flex--column width--100"
        v-if="selection === 'Forumskategorie'"
      >
        <div class="flex--column width--100">
          <label for="forumCategory" class="input--label">Titel</label>
          <input
            id="forumCategory"
            class="input--text mar--bottom__2"
            v-model="title"
          />
        </div>
        <label for="forumCategory_desc" class="input--label"
          >Beschreibung</label
        >
        <textarea
          id="forumCategory_desc"
          class="input--text"
          v-model="description"
        />

        <UploadImage
          @file-uploaded="cacheThumb"
          :cached-thumb-name="cachedThumbName"
        />
      </div>
      <div class="flex--column width--100" v-if="selection === 'Interesse'">
        <label for="interest" class="input--label">Interesse an</label>
        <input
          id="interest"
          class="input--text mar--bottom__2"
          v-model="title"
        />
      </div>
      <div class="flex--column width--100" v-if="selection === 'Fähigkeit'">
        <label for="skill_title" class="input--label">Titel</label>
        <input
          id="skill_title"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <div class="input--label">Standard Bild</div>
        <div
          class="flex flex--center width--100 pad--2"
          v-if="content && content.upload.file"
        >
          <img
            :src="
              `${$store.getters.URL}uploads/fetch/${this.content.upload.file}?authentication=${token}`
            "
            class="height--max__10"
          />
        </div>
        <UploadImage
          @file-uploaded="cacheThumb"
          :cached-thumb-name="cachedThumbName"
        />
        <div class="input--label">Levels</div>
        <div
          class="flex flex--column width--100 border--light b-radius--5 bgColor--white mar--bottom__1 pad--2"
          v-for="(level, ind) in levels"
          :key="ind"
        >
          <label :for="`skillStep${ind}`" class="input--label">Stufe</label>
          <input
            :id="`skillStep${ind}`"
            class="input--text mar--bottom__1"
            v-model="levels[ind].weight"
            type="number"
          />
          <div
            class="flex flex--center width--100 pad--2"
            v-if="content && level.upload.file"
          >
            <img
              :src="
                `${$store.getters.URL}uploads/fetch/${level.upload.file}?authentication=${token}`
              "
              class="height--max__10"
            />
          </div>
          <UploadImage
            @file-uploaded="el => cacheThumbs(el, ind)"
            :cached-thumb-name="cachedThumbNames[ind]"
          />
          <label :for="`skillDesc${ind}`" class="input--label"
            >Beschreibung</label
          >
          <textarea
            :id="`skillDesc${ind}`"
            class="input--text input--text__area mar--bottom__1"
            v-model="levels[ind].title"
          />
          <label :for="`buttonTitle${ind}`" class="input--label">Tests</label>
          <div
            class="flex flex--center__vert"
            v-for="(test, ind2) in level.description"
            :key="`test${ind2}`"
          >
            <textarea
              :id="`buttonTitle${ind}`"
              class="input--text input--text__area mar--bottom__1"
              v-model="level.description[ind2]"
            />
            <div class="mar--left__1">
              <ActionButton
                icon="close"
                light="true"
                @onClick="
                  level.description = level.description.filter(
                    el => el !== test,
                  )
                "
              />
            </div>
          </div>
          <div class="flex">
            <AccentButton
              title="Test hinzufügen"
              @onClick="levels[ind].description.push('')"
            />
            <AccentButton
              title="Löschen"
              @onClick="levels = levels.filter(el => el !== level)"
              danger="true"
            />
          </div>
        </div>
        <div class="flex flex--center mar--bottom__2">
          <ActionButton
            icon="add"
            accent="true"
            @onClick="
              levels.push({
                title: '',
                description: [''],
                weight: 1,
                upload: {},
              })
            "
          />
        </div>
      </div>
      <div class="flex--column width--100" v-if="selection === 'Impfung'">
        <label for="vaccine" class="input--label">Impfung</label>
        <input
          id="vaccine"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <div class="input--label">Verpflichtend</div>
        <input
          id="mandatory"
          type="checkbox"
          v-model="mandatory"
          class="input--checkbox"
        />
        <label for="mandatory" class="flex flex--center">
          <ion-icon name="checkmark" class="font--s3 color--primary" />
        </label>
      </div>
      <div class="flex--column width--100" v-if="selection === 'Ankündigung'">
        <label for="announce_title" class="input--label">Titel</label>
        <input
          id="announce_title"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <label for="announce_description" class="input--label"
          >Beschreibung</label
        >
        <textarea
          id="announce_description"
          class="input--text"
          v-model="description"
        />
        <label for="announce_priority" class="input--label"
          >Priorität (0 - 10)</label
        >
        <input
          id="announce_priority"
          class="input--text mar--bottom__2"
          v-model="priority"
        />
        <label for="announce_version" class="input--label">Für Version</label>
        <input
          id="announce_version"
          class="input--text mar--bottom__2"
          v-model="version"
        />
        <div class="flex">
          <div class="flex flex--column mar--right__2">
            <div class="input--label">Für iOS</div>
            <div class="flex">
              <input
                id="announce_ios"
                type="checkbox"
                v-model="forIos"
                class="input--checkbox"
              />
              <label for="announce_ios" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <div class="flex flex--column mar--right__2">
            <div class="input--label">Für Android</div>
            <div class="flex">
              <input
                id="announce_android"
                type="checkbox"
                v-model="forAndroid"
                class="input--checkbox"
              />
              <label for="announce_android" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <div class="flex flex--column">
            <div class="input--label">Für niedrigere Versionen</div>
            <div class="flex">
              <input
                id="announce_forLower"
                type="checkbox"
                v-model="mandatory"
                class="input--checkbox"
              />
              <label for="announce_forLower" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
        </div>
        <div class="input--label">Knöpfe</div>
        <div
          class="flex flex--column width--100 border--light b-radius--5 bgColor--white mar--bottom__1 pad--2"
          v-for="(button, ind) in buttons"
          :key="ind"
        >
          <label :for="`buttonLink${ind}`" class="input--label">Link</label>
          <input
            :id="`buttonLink${ind}`"
            class="input--text mar--bottom__1"
            v-model="buttons[ind].link"
          />
          <label :for="`buttonTitle${ind}`" class="input--label">Titel</label>
          <input
            :id="`buttonTitle${ind}`"
            class="input--text mar--bottom__1"
            v-model="buttons[ind].text"
          />
          <div class="flex flex--column mar--right__2">
            <div class="input--label">Schließend</div>
            <div class="flex">
              <input
                :id="`dismissible${ind}`"
                type="checkbox"
                v-model="buttons[ind].dismissible"
                class="input--checkbox"
              />
              <label :for="`dismissible${ind}`" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <AccentButton
            title="Löschen"
            @onClick="buttons = buttons.filter(el => el !== button)"
          />
        </div>
        <div class="flex flex--center mar--bottom__2">
          <ActionButton
            icon="add"
            accent="true"
            @onClick="
              buttons.push({
                dismissible: false,
                text: '',
                link: '',
              })
            "
          />
        </div>
      </div>
      <div class="flex--column width--100" v-if="selection === 'Post'">
        <label for="post_title" class="input--label">Titel</label>
        <input
          disabled
          id="post_title"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <label for="report_post_description" class="input--label"
          >Beschreibung</label
        >
        <textarea
          id="report_post_description"
          class="input--text height--10 mar--bottom__2"
          disabled
          v-model="description"
        />
        <label for="post_tags" class="input--label">Themen</label>
        <input
          disabled
          id="post_tags"
          class="input--text mar--bottom__2"
          v-model="tags"
        />
        <label for="post_user" class="input--label">Nutzer</label>
        <input
          disabled
          id="post_user"
          class="input--text mar--bottom__2"
          :value="user.firstName + user.lastName"
        />
        <div v-for="(img, ind) in content.uploads" :key="img._id">
          <div
            class="flex flex--center width--100 pad--2"
            v-if="
              content &&
                content.uploads[ind].file &&
                content.uploads[ind].mimetype.includes('image')
            "
          >
            <img
              :src="
                `${$store.getters.URL}uploads/fetch/${content.uploads[ind].file}?authentication=${token}`
              "
              class="width--50"
            />
          </div>
        </div>
      </div>
      <div class="flex--column width--100" v-if="selection === 'Hund'">
        <label for="dog_name" class="input--label">Name</label>
        <input
          disabled
          id="dog_name"
          class="input--text mar--bottom__2"
          v-model="title"
        />
        <label for="dog_age" class="input--label">Alter</label>
        <input
          disabled
          id="dog_age"
          class="input--text mar--bottom__2"
          v-model="age"
        />
        <label for="dog_breed" class="input--label">Rasse</label>
        <input
          disabled
          id="dog_breed"
          class="input--text mar--bottom__2"
          v-model="dogbreed"
        />
        <label for="dog_weight" class="input--label">Gewicht</label>
        <input
          disabled
          id="dog_weight"
          class="input--text mar--bottom__2"
          v-model="weight"
        />
        <div
          class="flex flex--center width--100 pad--2"
          v-if="content && content.upload.file"
        >
          <img
            :src="
              `${$store.getters.URL}uploads/fetch/${this.content.upload.file}?authentication=${token}`
            "
            class="width--50"
          />
        </div>
      </div>
      <div
        class="flex--column width--100"
        v-if="selection === 'Nutzer' || selection === 'Administrator'"
      >
        <div class="input--label">Geschlecht</div>
        <v-select
          :options="genderList"
          :clearable="false"
          v-model="gender"
          class="input--selection width--100 mar--bottom__2"
        />
        <label for="user_firstname" class="input--label">Vorname</label>
        <input
          id="user_firstname"
          class="input--text mar--bottom__2"
          v-model="firstName"
        />
        <label for="user_lastname" class="input--label">Nachname</label>
        <input
          id="user_lastname"
          class="input--text mar--bottom__2"
          v-model="lastName"
        />
        <label for="user_email" class="input--label">Email</label>
        <input
          id="user_email"
          class="input--text mar--bottom__2"
          v-model="email"
        />

        <div class="flex flex--gap-10">
          <div class="flex--column flex--start">
            <div class="input--label">Nutzer gesperrt</div>
            <div class="flex">
              <input
                id="user_block"
                type="checkbox"
                v-model="blocked"
                class="input--checkbox"
              />
              <label for="user_block" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <div class="flex--column flex--start">
            <div class="input--label">Trainer</div>
            <div class="flex">
              <input
                id="user_is_trainer"
                type="checkbox"
                v-model="isTrainer"
                class="input--checkbox"
              />
              <label for="user_is_trainer" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <div class="flex--column flex--start">
            <div class="input--label">Experte</div>
            <div class="flex">
              <input
                id="user_is_expert"
                type="checkbox"
                v-model="isExpert"
                class="input--checkbox"
              />
              <label for="user_is_expert" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
          <div class="flex--column flex--start">
            <div class="input--label">Team</div>
            <div class="flex">
              <input
                id="user_is_team"
                type="checkbox"
                v-model="isTeam"
                class="input--checkbox"
              />
              <label for="user_is_team" class="flex flex--center">
                <ion-icon
                  name="checkmark"
                  class="font--s3 color--primary flex flex--center"
                />
              </label>
            </div>
          </div>
        </div>

        <div
          v-if="selection === 'Nutzer'"
          style="margin-top: 2rem; margin-bottom: 4rem;"
        >
          <div class="input--label">Mitgliedschaft & Kooperation</div>

          <div
            v-for="record in coopRecords"
            :key="record._id"
            style="margin-top: 1rem;"
          >
            <div style="margin-top: 2rem;">
              <div class="input--label">{{ record.partner.title }}</div>
              <div
                class="width--100 grid list--entry__grid5 font--s14"
                style="margin-top: 1rem"
                v-if="record.data.dogs.length > 0"
              >
                <div class="flex flex--column flex--center__hor lineHeight--1">
                  <div class="font--s1 color--grey">
                    Partnerschaft
                  </div>
                </div>
                <div class="flex flex--column flex--center__hor  lineHeight--1">
                  <div class="font--s1 color--grey">
                    Hund
                  </div>
                </div>
                <div class="flex flex--column flex--center__hor  lineHeight--1">
                  <div class="font--s1 color--grey">
                    Chipnummer
                  </div>
                </div>
                <div class="flex flex--column flex--center__hor lineHeight--1">
                  <div class="font--s1 color--grey">
                    Beitritt
                  </div>
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1"
                ></div>
              </div>
              <div
                class="width--100 grid list--entry__grid5 font--s14"
                style="margin-top: 1rem"
                v-for="(dog, i) in record.data.dogs"
                :key="i"
              >
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  {{ record.partner.subtitle }}
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  <div v-if="editingCoopRecordId !== `${record._id}${i}`">
                    {{ dog.name }}
                  </div>
                  <div v-else>
                    <input
                      class="input--text mar--bottom__1 font--w700"
                      style="width: auto;"
                      v-model="editingCoopRecord.data.dogs[i].name"
                    />
                  </div>
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  <div v-if="editingCoopRecordId !== `${record._id}${i}`">
                    {{ dog.chipId }}
                  </div>
                  <div v-else>
                    <input
                      class="input--text mar--bottom__1"
                      style="width: auto;"
                      v-model="editingCoopRecord.data.dogs[i].chipId"
                    />
                  </div>
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  {{ moment(record.createdAt).format('DD.MM.YYYY') }}
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1"
                  style="align-items: end;"
                >
                  <div v-if="editingCoopRecordId !== `${record._id}${i}`">
                    <ion-icon
                      name="pencil"
                      class="font--s3"
                      style="cursor: pointer"
                      @click="selectCoopRecord(record, i)"
                    />
                  </div>
                  <div v-else>
                    <AccentButton
                      style="width: 100px;"
                      title="Speichern"
                      @onClick="saveDog"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div style="margin-top: 2rem; border-bottom: 1px solid #ddd;" />
          </div>
          <div v-if="coopCoupons && coopCoupons.length > 0">
            <div
              v-for="coupon in coopCoupons"
              :key="coupon._id"
              style="margin-top: 2rem;"
            >
              <div class="input--label">{{ coupon.partner.title }}</div>
              <div
                class="width--100 grid list--entry__grid4 font--s14"
                style="margin-top: 1rem"
              >
                <div class="flex flex--column flex--center__hor lineHeight--1">
                  <div class="font--s1 color--grey">
                    Partnerschaft
                  </div>
                </div>
                <div class="flex flex--column flex--center__hor lineHeight--1">
                  <div class="font--s1 color--grey">
                    Gutscheincode
                  </div>
                </div>
                <div class="flex flex--column flex--center__hor lineHeight--1">
                  <div class="font--s1 color--grey">
                    Beitritt
                  </div>
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1"
                ></div>
              </div>

              <div
                class="width--100 grid list--entry__grid4 font--s14"
                style="margin-top: 1rem"
              >
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  {{ coupon.partner.subtitle }}
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  {{ coupon.code }}
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1 font--w700"
                >
                  {{ moment(coupon.createdAt).format('DD.MM.YYYY') }}
                </div>
                <div
                  class="flex flex--column flex--center__hor lineHeight--1"
                ></div>
              </div>
              <div style="margin-top: 2rem; border-bottom: 1px solid #ddd;" />
            </div>
          </div>
        </div>
      </div>

      <div class="mar--bottom__1">
        <AccentButton
          title="Speichern"
          @onClick="$route.params.type === 'new' ? create() : update()"
        />
      </div>
      <div class="flex flex--end__hor">
        <div class="mar--right__1">
          <AccentButton
            title="Passwort zurücksetzen"
            danger="true"
            v-if="$route.params.type === 'Nutzer'"
            @onClick="forgotPassword"
          />
        </div>
        <div class="mar--right__1">
          <AccentButton
            :title="
              content && content.deactivated
                ? 'Post aktivieren'
                : 'Post deaktivieren'
            "
            :danger="content && !content.deactivated"
            v-if="$route.params.type === 'Posts'"
            @onClick="deactivatePost"
          />
        </div>
        <AccentButton
          danger="true"
          title="Löschen"
          @onClick="deleteItem(false)"
          v-if="
            content && selection !== 'Nutzer' && selection !== 'Administrator'
          "
        />
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <Modal
        title="Achtung! Wirklich löschen?"
        v-if="showDeleteConfirmation"
        @toggle="showDeleteConfirmation = false"
      >
        <div
          class="flex flex--column flex--center pad--22 width--100 height--100"
        >
          <div class="mar--bottom__1 font--s16 pad--1">
            Durch das Löschen wird der Nutzer ebenfalls bei OKourse gelöscht und
            alle Fortschritte und gekauften Kurse gehen verloren.
          </div>
          <div class="width--100 flex pad--1">
            <AccentButton
              title="Nein"
              class="width--100"
              @onClick="showDeleteConfirmation = false"
            />
            <AccentButton
              danger="true"
              title="Löschen"
              @onClick="deleteItem(true)"
            />
          </div>
        </div>
      </Modal>
    </transition>
  </div>
</template>
<script>
import 'vue-select/dist/vue-select.css'
import vSelect from 'vue-select'
import ActionButton from '../../components/ActionButton'
import AccentButton from '../../components/AccentButton'
import UploadImage from '../../components/UploadImage'
import _ from 'lodash'
import Modal from '../../components/Modal'
export default {
  name: 'OverviewCreate',
  components: { Modal, UploadImage, AccentButton, ActionButton, vSelect },
  data() {
    return {
      showDeleteConfirmation: false,
      token: sessionStorage.getItem('DogU-AuthToken'),
      // options: ['Nutzer', 'Administrator', 'Hunderasse', 'Interesse', 'Impfung', 'Fähigkeit', 'Ankündigung', 'Post', 'Hund'],
      genderList: ['Male', 'Female', 'Diverse'],
      selection: null,
      gender: null,
      content: null,
      title: null,
      description: null,
      tags: [],
      user: null,
      firstName: null,
      lastName: null,
      email: null,
      blocked: false,
      cachedThumb: null,
      cachedThumbName: null,
      changedBreedImg: false,
      cachedThumbs: [null, null, null],
      cachedThumbNames: [null, null, null],
      age: null,
      weight: null,
      male: false,
      dogbreed: null,
      activity: null,
      mandatory: false,
      forIos: false,
      forAndroid: false,
      version: '',
      priority: 1,
      buttons: [
        {
          dismissible: false,
          text: '',
          link: '',
        },
      ],
      levels: [
        {
          title: '',
          description: [''],
          weight: 1,
        },
      ],
      isTrainer: false,
      isExpert: false,
      isTeam: false,
      editingCoopRecordId: null,
      editingCoopRecord: null,
    }
  },
  watch: {
    // would be better to implement this with a radio button but then we need to style one
    isTrainer(newVal) {
      if (newVal === true) {
        this.isExpert = false
        this.isTeam = false
      }
    },
    isExpert(newVal) {
      if (newVal === true) {
        this.isTrainer = false
        this.isTeam = false
      }
    },
    isTeam(newVal) {
      if (newVal === true) {
        this.isTrainer = false
        this.isExpert = false
      }
    },
  },
  computed: {
    options() {
      if (this.$route.params.type === 'new') {
        return [
          'Nutzer',
          'Administrator',
          'Hunderasse',
          'Forumskategorie',
          'Interesse',
          'Impfung',
          'Fähigkeit',
          'Ankündigung',
        ]
      } else {
        return [
          'Nutzer',
          'Administrator',
          'Hunderasse',
          'Forumskategorie',
          'Interesse',
          'Impfung',
          'Fähigkeit',
          'Ankündigung',
          'Post',
          'Hund',
        ]
      }
    },
    users: {
      get() {
        return this.$store.getters.users
      },
    },
    dogBreeds: {
      get() {
        return this.$store.getters.dogBreeds
      },
    },
    dogs: {
      get() {
        return this.$store.getters.dogs
      },
    },
    forumCategories: {
      get() {
        return this.$store.getters.forumCategories
      },
    },
    interests: {
      get() {
        return this.$store.getters.interests
      },
    },
    posts: {
      get() {
        return this.$store.getters.posts
      },
    },
    vaccines: {
      get() {
        return this.$store.getters.vaccines
      },
    },
    announcements: {
      get() {
        return this.$store.getters.announcements
      },
    },
    skills: {
      get() {
        return this.$store.getters.skills
      },
    },
    coopRecords: {
      get() {
        return this.$store.getters.coopRecords
      },
    },
    coopCoupons: {
      get() {
        return this.$store.getters.coopCoupons
      },
    },
  },
  methods: {
    selectCoopRecord(record, i) {
      this.editingCoopRecordId = `${record._id}${i}`
      this.editingCoopRecord = _.cloneDeep(record)
    },
    async saveDog() {
      await this.$store.dispatch('patchCoopRecord', {
        id: this.editingCoopRecord._id,
        data: {
          concerns: this.editingCoopRecord.concerns,
          data: {
            dogs: this.editingCoopRecord.data.dogs,
          },
        },
      })

      await this.$store.dispatch('getCoopRecordsForUser', {
        userId: this.$route.params.id,
      })

      this.editingCoopRecordId = null
    },
    selectRecordId(id) {
      this.editingCoopRecordId = id
    },
    cacheThumb(fileList) {
      const formData = new FormData()
      formData.append('files', fileList[0], fileList[0].name)
      this.cachedThumb = formData
      this.cachedThumbName = fileList[0].name
      this.changedBreedImg = true
    },
    cacheThumbs(fileList, ind) {
      const formData = new FormData()
      formData.append('files', fileList[0], fileList[0].name)
      this.cachedThumbs[ind] = formData
      this.cachedThumbNames[ind] = fileList[0].name
      this.$forceUpdate()
      console.log(this.cachedThumbNames)
    },
    forgotPassword() {
      let success = true
      this.$store
        .dispatch('forgotPassword', {
          email: this.email,
        })
        .catch(() => {
          success = false
        })
        .then(() => {
          if (success) {
            this.$store.commit('addToast', {
              type: 'success',
              title: 'Erfolgreich zurückgesetzt',
            })
          }
        })
    },
    deactivatePost() {
      let success = true
      this.$store
        .dispatch('updatePost', {
          id: this.content._id,
          data: {
            deactivated: !this.content.deactivated,
          },
        })
        .catch(() => {
          success = false
          this.$store.commit('addToast', {
            type: 'error',
            title: 'Der Beitrag konnte nicht deaktiviert werden',
          })
        })
        .then(() => {
          if (success)
            this.$store.commit('addToast', {
              type: 'success',
              title: 'Beitrag erfolgreich deaktiviert',
            })
        })
    },
    isHanseMerkur(id) {
      return id === '649d4cce625a11eea2008b0b'
    },
    async create() {
      let id
      let platforms
      switch (this.selection) {
        case 'Hunderasse':
          id = await this.$store.dispatch('createDogBreed', {
            title: this.title,
          })
          if (this.cachedThumb) {
            this.cachedThumb.append('type', 'dogbreed')
            this.cachedThumb.append('id', id)
            await this.$store.dispatch('uploadBreedImage', this.cachedThumb)
            this.cachedThumb = null
            this.cachedThumbName = null
          }
          this.$router.go(-1)
          break
        case 'Forumskategorie':
          id = await this.$store.dispatch('createForumCategory', {
            title: this.title,
            description: this.description,
          })
          if (this.cachedThumb) {
            this.cachedThumb.append('type', 'category')
            this.cachedThumb.append('id', id)
            await this.$store.dispatch(
              'uploadForumCategoryImage',
              this.cachedThumb,
            )
            this.cachedThumb = null
            this.cachedThumbName = null
          }
          this.$router.go(-1)
          break
        case 'Interesse':
          await this.$store.dispatch('createInterest', {
            value: this.title,
          })
          this.$router.go(-1)
          break
        case 'Impfung':
          await this.$store.dispatch('createVaccine', {
            name: this.title,
            mandatory: this.mandatory,
          })
          this.$router.go(-1)
          break
        case 'Ankündigung':
          platforms = []
          if (this.forAndroid) platforms.push('android')
          if (this.forIos) platforms.push('ios')
          await this.$store.dispatch('createAnnouncement', {
            title: this.title,
            description: this.description,
            version: this.version,
            platform: platforms,
            lowerVersions: this.mandatory,
            priority: parseInt(this.priority, 10),
            buttons: this.buttons,
          })
          this.$router.go(-1)
          break
        case 'Administrator':
          await this.$store.dispatch('createAdmin', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            sex: this.gender,
            roles: ['user', 'admin'],
          })
          this.$router.go(-1)
          break
        case 'Nutzer':
          await this.$store.dispatch('createUser', {
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            sex: this.gender,
            isTrainer: this.isTrainer,
            isExpert: this.isExpert,
            isTeam: this.isTeam,
          })
          this.$router.go(-1)
          break
        case 'Fähigkeit':
          await this.$store
            .dispatch('createSkill', {
              title: this.title,
            })
            .then(async id => {
              if (this.cachedThumb) {
                this.cachedThumb.append('type', 'skill')
                this.cachedThumb.append('skill', id)
                await this.$store.dispatch('uploadImage', this.cachedThumb)
                this.cachedThumb = null
                this.cachedThumbName = null
              }
              for (let i = 0; i < this.levels.length; i++) {
                await this.$store
                  .dispatch('createLevel', {
                    title: this.levels[i].title,
                    description: this.levels[i].description,
                    skill: id,
                    weight: this.levels[i].weight,
                  })
                  .then(async levelID => {
                    if (this.cachedThumbs[i]) {
                      this.cachedThumbs[i].append('type', 'level')
                      this.cachedThumbs[i].append('level', levelID)
                      await this.$store.dispatch(
                        'uploadImage',
                        this.cachedThumbs[i],
                      )
                      this.cachedThumbs[i] = null
                      this.cachedThumbNames[i] = null
                    }
                  })
              }
            })
          this.$router.go(-1)
          break
        default:
      }
    },
    async update() {
      let platforms
      switch (this.selection) {
        case 'Hunderasse':
          await this.$store.dispatch('updateDogBreed', {
            id: this.content._id,
            data: {
              title: this.title,
            },
          })
          if (this.cachedThumb) {
            this.cachedThumb.append('type', 'dogbreed')
            this.cachedThumb.append('id', this.content._id)
            await this.$store.dispatch('uploadBreedImage', this.cachedThumb)
            this.cachedThumb = null
            this.cachedThumbName = null
          }
          this.$router.go(-1)
          break
        case 'Forumskategorie':
          await this.$store.dispatch('updateForumCategory', {
            id: this.content._id,
            data: {
              title: this.title,
              description: this.description,
            },
          })
          if (this.cachedThumb) {
            this.cachedThumb.append('type', 'category')
            this.cachedThumb.append('id', this.content._id)
            await this.$store.dispatch(
              'uploadForumCategoryImage',
              this.cachedThumb,
            )
            this.cachedThumb = null
            this.cachedThumbName = null
          }
          this.$router.go(-1)
          break
        case 'Interesse':
          await this.$store.dispatch('updateInterest', {
            id: this.content._id,
            data: {
              value: this.title,
            },
          })
          this.$router.go(-1)
          break
        case 'Impfung':
          await this.$store.dispatch('updateVaccine', {
            id: this.content._id,
            data: {
              name: this.title,
              mandatory: this.mandatory,
            },
          })
          this.$router.go(-1)
          break
        case 'Ankündigung':
          platforms = []
          if (this.forAndroid) platforms.push('android')
          if (this.forIos) platforms.push('ios')
          await this.$store.dispatch('updateAnnouncement', {
            id: this.content._id,
            data: {
              title: this.title,
              description: this.description,
              version: this.version,
              platform: platforms,
              lowerVersions: this.mandatory,
              priority: parseInt(this.priority, 10),
              buttons: this.buttons,
            },
          })
          this.$router.go(-1)
          break
        case 'Nutzer':
          await this.$store.dispatch('updateUser', {
            id: this.content._id,
            data: {
              firstName:
                this.firstName !== this.content.firstName
                  ? this.firstName
                  : undefined,
              lastName:
                this.lastName !== this.content.lastName
                  ? this.lastName
                  : undefined,
              email: this.email !== this.content.email ? this.email : undefined,
              sex:
                this.gender !== this.content.gender ? this.gender : undefined,
              locked:
                this.blocked !== this.content.locked ? this.blocked : undefined,
              isTrainer:
                this.isTrainer !== this.content.isTrainer
                  ? this.isTrainer
                  : undefined,
              isExpert:
                this.isExpert !== this.content.isExpert
                  ? this.isExpert
                  : undefined,
              isTeam:
                this.isTeam !== this.content.isTeam ? this.isTeam : undefined,
            },
          })
          this.$router.go(-1)
          break
        case 'Fähigkeit':
          await this.$store
            .dispatch('updateSkill', {
              id: this.content._id,
              data: {
                title: this.title,
              },
            })
            .then(async () => {
              if (this.cachedThumb) {
                this.cachedThumb.append('type', 'skill')
                this.cachedThumb.append('skill', this.content._id)
                await this.$store.dispatch('uploadImage', this.cachedThumb)
                this.cachedThumb = null
                this.cachedThumbName = null
              }
              for (let i = 0; i < this.levels.length; i++) {
                if (this.levels[i]._id) {
                  await this.$store
                    .dispatch('updateLevel', {
                      id: this.levels[i]._id,
                      data: {
                        title: this.levels[i].title,
                        description: this.levels[i].description,
                        weight: this.levels[i].weight,
                      },
                    })
                    .then(async () => {
                      if (this.cachedThumbs[i]) {
                        this.cachedThumbs[i].append('type', 'level')
                        this.cachedThumbs[i].append('level', this.levels[i]._id)
                        await this.$store.dispatch(
                          'uploadImage',
                          this.cachedThumbs[i],
                        )
                        this.cachedThumbs[i] = null
                        this.cachedThumbNames[i] = null
                      }
                    })
                } else {
                  await this.$store
                    .dispatch('createLevel', {
                      title: this.levels[i].title,
                      description: this.levels[i].description,
                      skill: this.content._id,
                      weight: this.levels[i].weight,
                    })
                    .then(async levelID => {
                      if (this.cachedThumbs[i]) {
                        this.cachedThumbs[i].append('type', 'level')
                        this.cachedThumbs[i].append('level', levelID)
                        await this.$store.dispatch(
                          'uploadImage',
                          this.cachedThumbs[i],
                        )
                        this.cachedThumbs[i] = null
                        this.cachedThumbNames[i] = null
                      }
                    })
                }
              }
            })
          await this.content.levels.forEach(el => {
            if (!this.levels.includes(el)) {
              this.$store.dispatch('deleteLevel', el._id)
            }
          })
          this.$router.go(-1)
          break
        default:
      }
    },
    async deleteItem(confirmed) {
      switch (this.selection) {
        case 'Hunderasse':
          await this.$store.dispatch('deleteDogBreed', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        case 'Post':
          await this.$store.dispatch('deletePost', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        case 'Interesse':
          await this.$store.dispatch('deleteInterest', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        case 'Impfung':
          await this.$store.dispatch('deleteVaccine', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        case 'Ankündigung':
          await this.$store.dispatch('deleteAnnouncement', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        case 'Administrator':
        case 'Nutzer':
          if (!confirmed) {
            this.showDeleteConfirmation = true
          } else {
            await this.$store.dispatch('deleteUser', {
              id: this.content._id,
            })
            this.$router.go(-1)
            this.showDeleteConfirmation = false
          }
          break
        case 'Fähigkeit':
          await this.$store.dispatch('deleteSkill', {
            id: this.content._id,
          })
          this.$router.go(-1)
          break
        default:
      }
    },
  },
  async mounted() {
    switch (this.$route.params.type) {
      case 'Nutzer':
        await this.$store.dispatch('getCoopRecordsForUser', {
          userId: this.$route.params.id,
        })

        this.selection = 'Nutzer'
        this.content = this.users.find(el => el._id === this.$route.params.id)
        this.gender = this.content.sex
        this.firstName = this.content.firstName
        this.lastName = this.content.lastName
        this.email = this.content.email
        this.blocked = this.content.locked
        this.isTrainer = this.content.isTrainer
        this.isExpert = this.content.isExpert
        this.isTeam = this.content.isTeam
        await this.$store.dispatch('getCoopRecordsForUser', {
          userId: this.$route.params.id,
        })
        await this.$store.dispatch('getCouponsForUser', {
          coopPartnerIds: this.content.coopPartners,
          userId: this.$route.params.id,
        })
        break
      case 'Administrator':
        this.selection = 'Administrator'
        this.content = this.users.find(el => el._id === this.$route.params.id)
        this.gender = this.content.sex
        this.firstName = this.content.firstName
        this.lastName = this.content.lastName
        this.email = this.content.email
        break
      case 'Hunderassen':
        this.selection = 'Hunderasse'
        this.content = this.dogBreeds.find(
          el => el._id === this.$route.params.id,
        )
        console.log(this.content)
        this.title = this.content.title
        this.cachedThumbName = this.content.upload.file
          ? 'Bereits hochgeladen'
          : 'Bild hochladen'
        break
      case 'Forumskategorien':
        this.selection = 'Forumskategorie'
        this.content = this.forumCategories.find(
          el => el._id === this.$route.params.id,
        )
        console.log(this.content)
        this.title = this.content.title
        this.description = this.content.description
        this.cachedThumbName = this.content.upload.file
          ? 'Bereits hochgeladen'
          : 'Bild hochladen'
        break
      case 'Interessen':
        this.selection = 'Interesse'
        this.content = this.interests.find(
          el => el._id === this.$route.params.id,
        )
        console.log(this.content)
        this.title = this.content.value
        break
      case 'Impfungen':
        this.selection = 'Impfung'
        this.content = this.vaccines.find(
          el => el._id === this.$route.params.id,
        )
        console.log(this.content)
        this.title = this.content.name
        this.mandatory = this.content.mandatory
        break
      case 'Ankündigungen':
        this.selection = 'Ankündigung'
        this.content = this.announcements.find(
          el => el._id === this.$route.params.id,
        )
        console.log(this.content)
        this.mandatory = this.content.mandatory
        this.title = this.content.title
        this.description = this.content.description
        this.priority = this.content.priority
        this.version = this.content.version
        this.buttons = this.content.buttons.map(el => {
          return {
            text: el.text,
            link: el.link,
            dismissible: el.dismissible,
          }
        })
        this.forIos = this.content.platform.includes('ios')
        this.forAndroid = this.content.platform.includes('android')
        this.mandatory = this.content.lowerVersions
        break
      case 'Posts':
        this.selection = 'Post'
        this.content = this.posts.find(el => el._id === this.$route.params.id)
        console.log(this.content)
        this.title = this.content.title
        this.description = this.content.description
        this.tags = this.content.tags
        this.user = this.content.user
        break
      case 'Fähigkeiten':
        this.selection = 'Fähigkeit'
        this.content = _.cloneDeep(
          this.skills.find(el => el._id === this.$route.params.id),
        )
        console.log(this.content)
        this.title = this.content.title
        this.levels = this.content.levels
        break
      case 'Hunde':
        this.selection = 'Hund'
        this.content = this.dogs.find(el => el._id === this.$route.params.id)
        console.log(this.content)
        this.title = this.content.name
        this.age = this.content.age
        this.activity = this.content.activity
        this.dogbreed = this.content.dogbreed.title
        this.male = this.content.isMale
        this.user = this.content.user
        this.weight = this.content.weight
        break
      default:
    }
  },
}
</script>
