<template>
  <form
    enctype="multipart/form-data"
    tabindex="0"
    class="input--file mar--bottom__2 flex flex--center flex--column relative b-radius--5 pad--2"
  >
    <input
      type="file"
      @change="e => $emit('file-uploaded', e.target.files)"
      class="clickable width--100 height--100 opacity--0 absolute"
      tabindex="-1"
      ref="file"
      accept=".csv"
    />
    <div v-if="!cachedThumbName" class="flex flex--column flex--center">
      <i class="ion-md-cloud-upload font--s3 lineHeight--12" />
      <div class="font--s16" v-if="!uploadText">CSV hochladen</div>
      <div class="font--s16" v-if="uploadText">{{ uploadText }}</div>
    </div>
    <div v-if="loading" class="pad--2">
      <loader :loading="true" color="#114B5F" :size="'1rem'" />
    </div>
    <transition name="fade" mode="out-in">
      <div
        v-if="cachedThumbName && !loading"
        class="flex flex--column flex--center"
      >
        <i class="ion-md-checkmark font--s3 lineHeight--12" />
        <div class="font--s16">{{ cachedThumbName }}</div>
      </div>
    </transition>
  </form>
</template>
<script>
export default {
  name: 'UploadCsv',
  props: {
    cachedThumbName: {},
    loading: {
      type: Boolean,
      default: false,
    },
    uploadText: null,
  },
}
</script>
