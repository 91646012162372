<template>
    <div class="flex flex--column width--100">
        <div class="width--100 grid dashboard--split height--100">
            <div class="width--100 height--100 flex--column">
                <div class="pad--2">
                    <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                        <div class="font--s2 font--w700">
                            Kategorien
                        </div>
                        <div class="mar--left__1">
                            <ActionButton icon="add" accent="true" @onClick="$router.push('/courses/category/0')"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--column list overflowAuto height--80__abs">
                    <div class="categoryTile flex flex--space__hor flex--center__vert pad--2 mar--bottom__2">
                        <div class="font--s14 font--w700">
                            Doguniversity - Academy
                        </div>
                        <ActionButton icon="create" light="true" @onClick="$router.push('/courses/academy')"/>
                    </div>
                    <CategoryTile v-for="category in categories" :key="category._id"
                                  :category="category" :draggedEl="draggedEl"
                                  @dragEvent="(val) => handleDragEvent(val)" @dragEl="handleDragEl"
                                  @removeCourse="handleRemoveCourse" />
                </div>
            </div>
            <div class="width--100 height--100 flex--column overflowAuto">
                <div class="pad--2">
                    <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
                        <div class="font--s2 font--w700">
                            Alle Kurse
                        </div>
                        <div class="mar--left__1">
                            <ActionButton icon="add" accent="true" @onClick="$router.push('/courses/course/0')"/>
                        </div>
                    </div>
                </div>
                <div class="flex flex--column list overflowAuto height--80__abs">
                    <CourseTile v-for="course in courses" :key="course._id"
                                :course="course" @dragEl="handleDragEl" @deleteCourse="handleDeleteCourse(course._id)"/>

                </div>
            </div>
        </div>
        <div class="width--100 pad--1" v-if="changes">
            <AccentButton title="Änderungen Speichern" @onClick="updateCategories"/>
        </div>
    </div>
</template>
<script>
    import ActionButton from '@/components/ActionButton';
    import CourseTile from '@/components/CourseTile';
    import CategoryTile from '@/components/CategoryTile';
    import {reorderArray} from '@/services/helpers';
    import _ from 'lodash';
    import AccentButton from "../../components/AccentButton";
    export default {
        name: 'CoursesView',
        components: {AccentButton, CategoryTile, CourseTile, ActionButton},
        data() {
            return {
                draggedEl: null,
                courses: [],
                categories: [],
                changes: false,
            };
        },
        computed: {
            cachedCourses: {
                get() {
                    return this.$store.getters.courses;
                },
                set(val) {
                    this.$store.commit('setCourses', val);
                }
            },
            cachedCategories: {
                get() {
                    return this.$store.getters.courseCategories
                },
                set(val) {
                    this.$store.commit('setCourseCategories', val);
                }
            },
        },
        methods: {
            async handleDeleteCourse(id) {
                this.categories.forEach((el) => {
                    el.courses = el.courses.filter(el2 => el2._id !== id);
                });
                await this.updateCategories();
                await this.$store.dispatch('deleteCourse', id);
            },
            handleDragEvent(data) {
                if (data.type === 'reorder') {
                    const oldInd = this.categories.findIndex((el) => el._id === this.draggedEl.split('-')[1]);
                    const newInd = this.categories.findIndex((el) => el._id === data.to.split('-')[1]);
                    this.categories = reorderArray(this.categories, oldInd, newInd);
                    this.changes = true;
                } else {
                    const ind = this.categories.findIndex((el) => el._id === data.to.split('-')[1]);
                    console.log(ind);
                    console.log(this.categories[ind]);
                    if (this.categories[ind].courses.find((el) => el._id === this.draggedEl.split('-')[1]) == null) {
                        if (this.categories[ind].type === 'Highlight' && this.categories[ind].courses.length) {
                            return;
                        }
                        this.categories[ind].courses.push(this.courses.find((el) => el._id === this.draggedEl.split('-')[1]));
                    }
                    this.changes = true;
                }
            },
            handleDragEl(val) {
                this.draggedEl = val;
            },
            handleRemoveCourse(data) {
                const ind = this.categories.findIndex((el) => el._id === data.category);
                this.categories[ind].courses = this.categories[ind].courses.filter((el) => el._id !== data.course);
                this.changes = true;
            },
            async updateCategories() {
                for (let i = 0; i < this.categories.length; i++) {
                    const oldInd = this.cachedCategories.findIndex((el) => el._id === this.categories[i]._id);
                  await this.$store.dispatch('updateCourseCategory', {
                    id: this.categories[i]._id,
                    data: {
                      position: i,
                      courses: this.categories[i].courses.map(el => el._id) !== this.cachedCategories[oldInd].courses.map(el => el._id) ? this.categories[i].courses.map(el => el._id) : undefined,
                    },
                  })
                    /** if ((i !== oldInd) || (this.categories[i].courses.map(el => el._id) !== this.cachedCategories[oldInd].courses.map(el => el._id))) {
                        await this.$store.dispatch('updateCourseCategory', {
                            id: this.categories[i]._id,
                            data: {
                                position: i,
                                courses: this.categories[i].courses.map(el => el._id) !== this.cachedCategories[oldInd].courses.map(el => el._id) ? this.categories[i].courses.map(el => el._id) : undefined,
                            },
                        })
                    } **/
                }
                console.log('Fetch Kurse');
              this.$store.dispatch('retrieveCourseCategories');
              this.changes = false;
            },

        },
        watch: {
            cachedCourses(newVal) {
                this.courses = _.cloneDeep(newVal);
            },
            cachedCategories(newVal) {
                this.categories = _.cloneDeep(newVal);
            },
        },
        async mounted() {
            await this.$store.dispatch('retrieveOKourseCourses');
            await this.$store.dispatch('retrieveCourses');
            await this.$store.dispatch('retrieveCourseCategories');
            this.courses = _.cloneDeep(this.cachedCourses);
            this.categories = _.cloneDeep(this.cachedCategories);
        }
    }
</script>
