<template>
    <div class="width--100 height--100 flex--column">
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'email',
                        asc: 1,
                    })">
                        A - Z
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'email',
                        asc: -1,
                    })">
                        Z - A
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
                        Neueste Zuerst
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
                        Älteste Zuerst
                    </div>
                </PopUp>
            </div>
        </transition>
        <div class="pad--2 sticky top--0 front">
            <div class="flex flex--space__hor flex--center__vert pad--2 bgColor--white shadow--light b-radius--5">
                <v-select :options="options" :clearable="false" v-model="list" class="input--selection width--20 mar--right__1"/>
                <input type="text" class="input--text" placeholder="Suchen..." v-model="searchString"/>
                <div class="mar--left__1">
                    <ActionButton icon="swap-vertical" light="true" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>
                </div>
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/overview/new/0')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
                <loader :loading="true" color="#779d2b" size="50px" />
            </div>
            <div class="flex flex--column list overflowAuto" key="list" v-if="!isLoading">
                <transition-group name="list">
                    <div class="width--100 pad--1 flex flex--center font--w700 font--s16"
                         :key="'results'">
                        <div v-if="this.list !== 'Fähigkeiten'">
                            {{ listCount + ' Ergebnisse' }}
                        </div>
                    </div>
                    <div v-for="entry in listData" :key="entry._id" class="list--entry flex relative" @click="$router.push(`/overview/${list}/${entry._id}`)">
                        <div class="width--100 height--100 grid list--entry__grid3 font--s14">
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Nutzer'">
                                <div class="font--s1 color--grey">
                                    Nutzername
                                </div>
                                <div class="font--w700">
                                    {{ entry.firstName }} {{ entry.lastName}}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Nutzer'">
                                <div class="font--s1 color--grey">
                                    Email
                                </div>
                                <div class="font--w700">
                                    {{ entry.email }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Nutzer'">
                                <div class="font--s1 color--grey">
                                    Beitritt
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Posts'">
                                <div class="font--s1 color--grey">
                                    Titel
                                </div>
                                <div class="font--w700">
                                    {{ entry.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Posts'">
                                <div class="font--s1 color--grey">
                                    Von
                                </div>
                                <div class="font--w700">
                                    {{ entry.user.firstName }} {{ entry.user.lastName }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Posts'">
                                <div class="font--s1 color--grey">
                                    Datum
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunde'">
                                <div class="font--s1 color--grey">
                                    Hundename
                                </div>
                                <div class="font--w700">
                                    {{ entry.name }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunde'">
                                <div class="font--s1 color--grey">
                                    Von
                                </div>
                                <div class="font--w700">
                                    {{ entry.user.firstName }} {{ entry.user.lastName }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunde'">
                                <div class="font--s1 color--grey">
                                    Datum
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunderassen'">
                                <div class="font--s1 color--grey">
                                    Name
                                </div>
                                <div class="font--w700">
                                    {{ entry.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunderassen'">
                                <div class="font--s1 color--grey">
                                    Geändert am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.updatedAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Hunderassen'">
                                <div class="font--s1 color--grey">
                                    Erstellt am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                          <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Forumskategorien'">
                            <div class="font--s1 color--grey">
                              Title
                            </div>
                            <div class="font--w700">
                              {{ entry.title }}
                            </div>
                          </div>
                          <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Forumskategorien'">
                            <div class="font--s1 color--grey">
                              Posts
                            </div>
                            <div class="font--w700">
                              {{ entry.postCount || 0 }}
                            </div>
                          </div>
                          <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Forumskategorien'">
                            <div class="font--s1 color--grey">
                              Erstellt am
                            </div>
                            <div class="font--w700">
                              {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                            </div>
                          </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Interessen'">
                                <div class="font--s1 color--grey">
                                    Interesse an
                                </div>
                                <div class="font--w700">
                                    {{ entry.value }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Interessen'">
                                <div class="font--s1 color--grey">
                                    Erstellt am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Interessen'">
                                <div class="font--s1 color--grey">
                                    Geändert am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.updatedAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Impfungen'">
                                <div class="font--s1 color--grey">
                                    Name
                                </div>
                                <div class="font--w700">
                                    {{ entry.name }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Impfungen'">
                                <div class="font--s1 color--grey">
                                    Verpflichtend
                                </div>
                                <div class="font--w700">
                                    {{ entry.mandatory ? 'Verpflichtend' : 'Nicht verpflichtend' }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Impfungen'">
                                <div class="font--s1 color--grey">
                                    Geändert am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.updatedAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Ankündigungen'">
                                <div class="font--s1 color--grey">
                                    Titel
                                </div>
                                <div class="font--w700">
                                    {{ entry.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Ankündigungen'">
                                <div class="font--s1 color--grey">
                                    Plattformen
                                </div>
                                <div class="font--w700" v-if="entry.platform.length">
                                    {{ entry.platform[0] ? entry.platform[0].toUpperCase() : ''}} {{ entry.platform.length > 1 && entry.platform[1] ? `& ${entry.platform[1].toUpperCase()}` : null }}
                                </div>
                              <div v-if="!entry.platform.length">-</div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Ankündigungen'">
                                <div class="font--s1 color--grey">
                                    Geändert am
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.updatedAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Fähigkeiten'">
                                <div class="font--s1 color--grey">
                                    Titel
                                </div>
                                <div class="font--w700">
                                    {{ entry.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Fähigkeiten'">
                                <div class="font--s1 color--grey">
                                    Levels
                                </div>
                                <div class="font--w700">
                                    {{ entry.levels.length }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1" v-if="list === 'Fähigkeiten'">
                                <div class="font--s1 color--grey">
                                    Erstellt
                                </div>
                                <div class="font--w700">
                                    {{ moment(entry.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--center">
                                <ActionButton icon="pencil" accent="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageBack"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50}} bis {{ page * 50}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageForward"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import PopUp from "../../components/PopUp";
    export default {
        name: 'OverviewView',
        components: {PopUp, ActionButton, vSelect},
        data() {
            return {
                options: ['Nutzer','Posts', 'Hunde', 'Hunderassen', 'Forumskategorien', 'Interessen', 'Impfungen', 'Ankündigungen', 'Fähigkeiten'],
                list: 'Nutzer',
                isLoading: false,

                posX: null,
                posY: null,

                showMainPopup: false,
                page: 1,

                searchString: null,
            };
        },
        computed: {
            users: {
                get() {
                    return this.$store.getters.users;
                },
                set(val) {
                    this.$store.commit('setUsers', val);
                }
            },
            posts: {
                get() {
                    return this.$store.getters.posts
                },
                set(val) {
                    this.$store.commit('setPosts', val);
                }
            },
            dogs: {
                get() {
                    return this.$store.getters.dogs
                },
                set(val) {
                    this.$store.commit('setDogs', val);
                }
            },
            dogBreeds: {
                get() {
                    return this.$store.getters.dogBreeds
                },
                set(val) {
                    this.$store.commit('setDogBreeds', val);
                }
            },
          forumCategories: {
            get() {
              return this.$store.getters.forumCategories
            },
            set(val) {
              this.$store.commit('setForumCategories', val);
            },
          },
            interests: {
                get() {
                    return this.$store.getters.interests
                },
                set(val) {
                    this.$store.commit('setInterests', val);
                },
            },
            vaccines: {
                get() {
                    return this.$store.getters.vaccines
                },
                set(val) {
                    this.$store.commit('setVaccines', val);
                },
            },
            announcements: {
                get() {
                    return this.$store.getters.announcements
                },
                set(val) {
                    this.$store.commit('setAnnouncements', val);
                },
            },
            skills: {
                get() {
                    return this.$store.getters.skills
                },
                set(val) {
                    this.$store.commit('setSkills', val);
                },
            },
            listCount() {
                switch(this.list) {
                    case 'Nutzer':
                        return this.$store.getters.totalUsers;
                    case 'Posts':
                        return this.$store.getters.totalPosts;
                    case 'Hunde':
                        return this.$store.getters.totalDogs;
                    case 'Hunderassen':
                        return this.$store.getters.totalDogBreeds;
                  case 'Forumskategorien':
                    return this.$store.getters.totalForumCategories;
                    case 'Interessen':
                        return this.$store.getters.totalInterests;
                    case 'Impfungen':
                        return this.$store.getters.totalVaccines;
                    case 'Ankündigungen':
                        return this.$store.getters.totalAnnouncements;
                    case 'Fähigkeiten':
                        return this.$store.getters.totalSkills;
                    default:
                        return 'Keine';
                }
            },
            listData() {
                switch(this.list) {
                    case 'Nutzer':
                        return this.users;
                    case 'Posts':
                        return this.posts;
                    case 'Hunde':
                        return this.dogs;
                    case 'Hunderassen':
                        return this.dogBreeds;
                  case 'Forumskategorien':
                    return this.forumCategories;
                    case 'Interessen':
                        return this.interests;
                    case 'Impfungen':
                        return this.vaccines;
                    case 'Ankündigungen':
                        return this.$store.getters.announcements;
                    case 'Fähigkeiten':
                        return this.$store.getters.skills;
                    default:
                        return [];
                }
            }
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            async pageBack() {
                if (this.page > 1) {
                    this.isLoading = true;
                    this.page--;
                    switch(this.list) {
                        case 'Nutzer':
                            await this.$store.dispatch('retrieveAllUsers', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Posts':
                            await this.$store.dispatch('retrieveAllPosts', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Hunde':
                            await this.$store.dispatch('retrieveAllDogs', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Hunderassen':
                            await this.$store.dispatch('retrieveDogBreeds', {
                                skip: (this.page * 50) - 50
                            });
                            break;
                      case 'Forumskategorien':
                        await this.$store.dispatch('retrieveForumCategories', {
                          limit: 50,
                          skip: (this.page * 50) - 50
                        });
                        break;
                        case 'Interessen':
                            await this.$store.dispatch('retrieveInterests', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Impfungen':
                            await this.$store.dispatch('retrieveAllVaccines', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Ankündigungen':
                            await this.$store.dispatch('retrieveAnnouncements', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Fähigkeiten':
                            await this.$store.dispatch('retrieveSkills', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        default:
                            break;
                    }
                    await setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            },
            async pageForward() {
                if (this.page * 50 < this.listCount) {
                    this.isLoading = true;
                    this.page++;
                    switch(this.list) {
                        case 'Nutzer':
                            await this.$store.dispatch('retrieveAllUsers', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Posts':
                            await this.$store.dispatch('retrieveAllPosts', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Hunde':
                            await this.$store.dispatch('retrieveAllDogs', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Hunderassen':
                            await this.$store.dispatch('retrieveDogBreeds', {
                                skip: (this.page * 50) - 50
                            });
                            break;
                      case 'Forumskategorien':
                        await this.$store.dispatch('retrieveForumCategories', {
                          limit: 50,
                          skip: (this.page * 50) - 50
                        });
                        break;
                        case 'Interessen':
                            await this.$store.dispatch('retrieveInterests', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Impfungen':
                            await this.$store.dispatch('retrieveAllVaccines', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Ankündigungen':
                            await this.$store.dispatch('retrieveAnnouncements', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        case 'Fähigkeiten':
                            await this.$store.dispatch('retrieveSkills', {
                                limit: 50,
                                skip: (this.page * 50) - 50
                            });
                            break;
                        default:
                            break;
                    }
                    await setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            },
            async sort({
                attribute,
                asc
                 }) {
                switch(this.list) {
                    case 'Nutzer':
                        await this.$store.dispatch('retrieveAllUsers', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Posts':
                        await this.$store.dispatch('retrieveAllPosts', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'title' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Hunde':
                        await this.$store.dispatch('retrieveAllDogs', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'name' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Hunderassen':
                        await this.$store.dispatch('retrieveDogBreeds', {
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'name' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                  case 'Forumskategorien':
                    await this.$store.dispatch('retrieveForumCategories', {
                      limit: 50,
                      skip: (this.page * 50) - 50,
                      attribute: attribute === 'title' ? 'value' : attribute,
                      asc,
                      search: this.searchString,
                    });
                    break;
                    case 'Interessen':
                        await this.$store.dispatch('retrieveInterests', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'value' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Impfungen':
                        await this.$store.dispatch('retrieveAllVaccines', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'name' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Ankündigungen':
                        await this.$store.dispatch('retrieveAnnouncements', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'title' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    case 'Fähigkeiten':
                        await this.$store.dispatch('retrieveSkills', {
                            limit: 50,
                            skip: (this.page * 50) - 50,
                            attribute: attribute === 'email' ? 'title' : attribute,
                            asc,
                            search: this.searchString,
                        });
                        break;
                    default:
                        break;
                }
            },
        },
        watch: {
            async list(newVal) {
                this.isLoading = true;
                this.page = 1;
                this.searchString = null;
                switch(newVal) {
                    case 'Nutzer':
                        await this.$store.dispatch('retrieveAllUsers', {
                        limit: 50,
                        skip: 0,
                    });
                        break;
                    case 'Posts':
                        await this.$store.dispatch('retrieveAllPosts', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    case 'Hunde':
                        await this.$store.dispatch('retrieveAllDogs', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    case 'Hunderassen':
                        await this.$store.dispatch('retrieveDogBreeds', {
                            skip: 0,
                        });
                        break;
                  case 'Forumskategorien':
                    await this.$store.dispatch('retrieveForumCategories', {
                      limit: 50,
                      skip: 0,
                    });
                    break;
                    case 'Interessen':
                        await this.$store.dispatch('retrieveInterests', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    case 'Impfungen':
                        await this.$store.dispatch('retrieveAllVaccines', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    case 'Ankündigungen':
                        await this.$store.dispatch('retrieveAnnouncements', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    case 'Fähigkeiten':
                        await this.$store.dispatch('retrieveSkills', {
                            limit: 50,
                            skip: 0,
                        });
                        break;
                    default:
                        break;
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            async searchString(newVal) {
                this.isLoading = true;
                this.page = 1;
                switch(this.list) {
                    case 'Nutzer':
                        await this.$store.dispatch('retrieveAllUsers', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Posts':
                        await this.$store.dispatch('retrieveAllPosts', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Hunde':
                        await this.$store.dispatch('retrieveAllDogs', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Hunderassen':
                        await this.$store.dispatch('retrieveDogBreeds', {
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                  case 'Forumskategorien':
                    await this.$store.dispatch('retrieveForumCategories', {
                      limit: 50,
                      skip: 0,
                      search: newVal ? newVal : undefined,
                    });
                    break;
                    case 'Interessen':
                        await this.$store.dispatch('retrieveInterests', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Impfungen':
                        await this.$store.dispatch('retrieveAllVaccines', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Ankündigungen':
                        await this.$store.dispatch('retrieveAnnouncements', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    case 'Fähigkeiten':
                        await this.$store.dispatch('retrieveSkills', {
                            limit: 50,
                            skip: 0,
                            search: newVal ? newVal : undefined,
                        });
                        break;
                    default:
                        break;
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
        },
        async mounted() {
            if (!this.users || this.users.isEmpty) {
                this.isLoading = true;
                await this.$store.dispatch('retrieveAllUsers', {
                    limit: 50,
                    skip: 0,
                });
                await setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            } else {
                await this.$store.dispatch('retrieveAllUsers', {
                    limit: 50,
                    skip: 0,
                });
            }
        }
    }
</script>
