<template>
  <div class="width--100 height--100 flex--column">
    <transition name="fade">
      <div class="absolute popup--anchor" v-if="showMainPopup">
        <PopUp :posX="posX" :posY="posY">
          <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: 1,
                    })">
            A - Z
          </div>
          <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: -1,
                    })">
            Z - A
          </div>
          <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
            Neueste Zuerst
          </div>
          <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
            Älteste Zuerst
          </div>
        </PopUp>
      </div>
    </transition>
    <div class="pad--2 sticky top--0 front">
      <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
        <input type="text" class="input--text" placeholder="Suchen..." v-model="searchString"/>
        <div class="mar--left__1">
          <ActionButton icon="swap-vertical" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>
        </div>

        <div class="mar--left__1">
          <ActionButton icon="add" accent="true" @onClick="$router.push('/ads/new')"/>
        </div>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <div class="flex flex--center width--100 height--50" key="loader" v-if="isLoading">
        <loader :loading="true" color="#779d2b" size="50px" />
      </div>
      <div class="flex flex--column list overflowAuto" key="list" v-else>
        <transition-group name="list">
          <div class="width--100 pad--1 flex flex--center font--w700 font--s16" :key="'results'">
            {{ listCount + ' Ergebnisse' }}
          </div>
          <div v-for="ad in ads" :key="ad._id" class="list--entry" @click="$router.push(`/ads/${ad._id}`)">
            <div class="width--100 height--100 grid list--entry__grid5 font--s14">
              <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                  Titel
                </div>
                <div class="font--w700">
                  {{ ad.title }}
                </div>
              </div>
              <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                  Veröffentlicht
                </div>
                <div class="font--w700">
                  {{ ad.publish ? 'Ja' : 'Nein' }}
                </div>
              </div>
              <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                  Veröffentlicht
                </div>
                <div class="font--w700">
                  {{ ad.openExternal ? 'Externer Link' : ad.openInternal ? 'Interner Link' : 'Kein Link' }}
                </div>
              </div>
              <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                  Veröffentlicht
                </div>
                <div class="font--w700">
                  {{ ad.openExternal ? ad.openExternal.url : ad.openInternal ? ad.openInternal.type : '-' }}
                </div>
              </div>
              <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                <div class="font--s1 color--grey">
                  Bearbeitet
                </div>
                <div class="font--w700">
                  {{ moment(ad.modifiedAt).format('DD.MM.YYYY') }}
                </div>
              </div>
              <div class="flex flex--center">
                <ActionButton icon="pencil" accent="true"/>
              </div>
            </div>
          </div>
          <div class="width--100 pad--2 flex flex--center" :key="'footer'">
            <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageNavigation"/>
            <div class="font--w700">
              {{ (page - 1) * 50}} bis {{ page * 50}}
            </div>
            <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageNavigation(true)"/>
          </div>
        </transition-group>
      </div>
    </transition>
  </div>
</template>
<script>
import ActionButton from '@/components/ActionButton';
import PopUp from "../../components/PopUp";
export default {
  name: 'AdsOverview',
  components: {PopUp, ActionButton},
  data() {
    return {
      options: ['Offen','Geschlossen'],
      entries: [1,2,3,4],
      list: 'Offen',

      posX: null,
      posY: null,

      showMainPopup: false,
      isLoading: false,
      page: 1,

      searchString: null,
    };
  },
  methods: {
    toggleMainPopup(e) {
      this.posX = e.clientX;
      this.posY = e.clientY;
      this.showMainPopup = !this.showMainPopup;
    },
    async sort({ attribute, asc }) {
      if (this.list === 'Offen') {
        await this.$store.dispatch('retrieveAds', {
          limit: this.page * 50,
          skip: (this.page * 50) - 50,
          attribute,
          asc,
        });
      } else {
        await this.$store.dispatch('retrieveAds', {
          limit: this.page * 50,
          skip: (this.page * 50) - 50,
          attribute,
          asc,
        });
      }
    },
    async pageNavigation(forward) {
      if (forward) {
        if (this.page * 50 < this.listCount) {
          this.isLoading = true;
          this.page++;
          await this.$store.dispatch('retrieveAds', {
            limit: this.page * 50,
            skip: (this.page * 50) - 50,
            search: this.search,
          });
          await setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      }  else {
        if (this.page > 1) {
          this.isLoading = true;
          this.page--;
          await this.$store.dispatch('retrieveAds', {
            limit: this.page * 50,
            skip: (this.page * 50) - 50,
            search: this.search,
          });
          await setTimeout(() => {
            this.isLoading = false;
          }, 1000);
        }
      }
    },
  },
  computed: {
    ads: {
      get() {
        return this.$store.getters.ads;
      },
      set(val) {
        this.$store.commit('setAds', val);
      }
    },
    listCount() {
      if (this.$store.getters.totalAds > 0) {
        return this.$store.getters.totalAds;
      }
      return 'Keine ';
    }
  },
  watch: {
    async list(newVal) {
      this.searchString = null;
      this.isLoading = true;
      await this.$store.dispatch('retrieveAds', {
        limit: 50,
        skip: 0,
        search: newVal,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    },
    async searchString(newVal) {
      this.isLoading = true;
      await this.$store.dispatch('retrieveAds', {
        limit: 50,
        skip: 0,
        search: newVal,
      });
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    }
  },
  async mounted() {
    if (!this.ads || this.ads.isEmpty) {
      this.isLoading = true;
      await this.$store.dispatch('retrieveAds', {
        limit: 50,
        skip: 0,
      });
      await setTimeout(() => {
        this.isLoading = false;
      }, 1000);
    } else {
      await this.$store.dispatch('retrieveAds', {
        limit: 50,
        skip: 0,
      });
    }
  }
}
</script>
