import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ClickOutside from 'vue-click-outside';
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import moment from "moment";
import dotenv from 'dotenv';

dotenv.config();

Vue.config.productionTip = false;
Vue.config.ignoredElements = [
  'ion-icon',
];


Vue.component('loader', BounceLoader);


Vue.directive('ClickOutside', ClickOutside);


Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
