<template>
  <div
    class="grid width--100 height--100__abs dashboard bgColor--greyLight relative overflowHidden"
  >
    <div
      class="bgColor--white height--100__abs dashboard--nav__wrapper"
      :class="{ 'dashboard--nav__out': navOut }"
    >
      <div class="height--100 shadow--light grid dashboard--nav relative">
        <div class="pad--2 flex flex--center height--100 width--100 relative">
          <img src="../assets/images/Logo.png" class="height--100" />
          <div
            class="dashboard--nav__trigger flex--center bgColor--white shadow--light absolute"
            @click="navOut ? (navOut = false) : (navOut = true)"
          >
            <button
              class="burgerMenu burgerMenu--spin noDec"
              type="button"
              :class="{ 'is-active': !navOut }"
              @click="!navOut ? (navOut = false) : (navOut = true)"
            >
              <span class="burgerMenu-box">
                <span class="burgerMenu-inner" />
              </span>
            </button>
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === '',
              'color--white': tab[1] === '',
            }"
            @click="tab[1] === '' ? null : $router.push('/')"
          >
            Home
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'notifications',
              'color--white': tab[1] === 'notifications',
            }"
            @click="
              tab[1] === 'notifications' ? null : $router.push('/notifications')
            "
          >
            Benachrichtigungen
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'ads',
              'color--white': tab[1] === 'ads',
            }"
            @click="tab[1] === 'ads' ? null : $router.push('/ads')"
          >
            Werbung
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'knowledge',
              'color--white': tab[1] === 'knowledge',
            }"
            @click="tab[1] === 'knowledge' ? null : $router.push('/knowledge')"
          >
            Wissensangebot
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'courses',
              'color--white': tab[1] === 'courses',
            }"
            @click="tab[1] === 'courses' ? null : $router.push('/courses')"
          >
            Kursangebot
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'overview',
              'color--white': tab[1] === 'overview',
            }"
            @click="tab[1] === 'overview' ? null : $router.push('/overview')"
          >
            Übersicht
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'coop-partners',
              'color--white': tab[1] === 'coop-partners',
            }"
            @click="
              tab[1] === 'coop-partners' ? null : $router.push('/coop-partners')
            "
          >
            Kooperationspartner
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'reports',
              'color--white': tab[1] === 'reports',
            }"
            @click="tab[1] === 'reports' ? null : $router.push('/reports')"
          >
            Reports
          </div>
        </div>
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
            :class="{
              'bgColor--primary': tab[1] === 'marker',
              'color--white': tab[1] === 'marker',
            }"
            @click="tab[1] === 'marker' ? null : $router.push('/marker')"
          >
            Marker
          </div>
        </div>
        <div />
        <div
          class="flex flex--center font--s14 font--w700 pad--05 clickable dashboard--nav__item"
          @click="logout"
        >
          <div
            class="flex flex--center pad--1 b-radius--5 width--100 transition"
          >
            Abmelden
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div
        class="dashboard--nav__backdrop absolute"
        v-if="!navOut"
        @click="navOut = true"
      />
    </transition>
    <div class="overflowAuto">
      <div class="dashboard--nav__placeholder" />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DashboardLoader',
  data() {
    return {
      navOut: true,
    }
  },
  computed: {
    tab() {
      return this.$route.path.split('/')
    },
  },
  methods: {
    async logout() {
      sessionStorage.clear()
      await this.$store.dispatch('logoutUser')
      this.$store.commit('setAuthenticated', false)
      this.$forceUpdate()
    },
  },
}
</script>
