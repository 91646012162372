<template>
    <div class="flex flex--column flex--center__vert width--100 height--100">
        <div class="dashboard--header">
            <ActionButton icon="arrow-back" @onClick="$router.push('/notifications')"/>
        </div>
        <div class="dashboard--content pad--2 flex flex--column flex--grow overflowAuto">
            <label for="notification_title" class="input--label">Titel</label>
            <input id="notification_title" class="input--text mar--bottom__2" v-model="title"/>
            <label for="notification_description" class="input--label">Beschreibung</label>
            <textarea id="notification_description" class="input--text height--min__30 mar--bottom__2" v-model="description" />
            <div class="input--label">Nutzerziel</div>
            <v-select :options="options" label="title" :clearable="false" v-model="type" class="input--selection width--100 mar--bottom__2"/>
            <div v-if="type && type.id === 3" class="flex flex--space__hor">
                <div class="input--label">
                    Kursliste
                </div>
                <div class="flex font--s14 font--w700">
                    <div class="mar--right__2">
                        Enthalten
                    </div>
                    <div>
                        Fehlend
                    </div>
                </div>
            </div>
            <div v-if="type && type.id === 3" class="flex flex--column">
                <div v-for="course in OKourses"
                     :key="course._id" class="flex flex--center__vert flex--space__hor border--bottom__light">
                    <div class="font--s16">{{ course.title }}</div>
                    <div class="flex">
                        <input :id="`included-${course._id}`"
                               type="checkbox"
                               :value="includedCourses.includes(course._id)"
                               @input="toggleCourse(course._id, true)" class="input--checkbox pad--05"/>
                        <label :for="`included-${course._id}`" class="flex flex--center">
                            <ion-icon name="checkmark" class="font--s3 color--primary" v-if="includedCourses.includes(course._id)"/>
                        </label>

                        <input :id="`excluded-${course._id}`"
                               type="checkbox"
                               :value="excludedCourses.includes(course._id)"
                               @input="toggleCourse(course._id, false)" class="input--checkbox pad--05"/>
                        <label :for="`excluded-${course._id}`" class="flex flex--center">
                            <ion-icon name="checkmark" class="font--s3 color--primary" v-if="excludedCourses.includes(course._id)"/>
                        </label>
                    </div>
                </div>
            </div>
            <label v-if="type && (type.id === 4 || type.id === 5)"
                   for="course_amount"
                   class="input--label">Kursanzahl</label>
            <input v-if="type && (type.id === 4 || type.id === 5)"
                   id="course_amount"
                   type="number"
                   min="1"
                   class="input--text mar--bottom__2"
                   v-model="courseAmount"/>
            <AccentButton :title="'Abschicken'" @onClick="sendNotification"/>
        </div>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import AccentButton from '@/components/AccentButton';
    export default {
        name: 'CreateNotificationView',
        components: {AccentButton, ActionButton, vSelect},
        data() {
            return {
                type: null,
                title: null,
                description: null,
                excludedCourses: [],
                includedCourses: [],
                courseAmount: null,
                options: [
                    {
                        id: 1,
                        title: 'Aktive Nutzer (14 Tage)',
                    },
                    {
                        id: 2,
                        title: 'Inaktive Nutzer (14 Tage)',
                    },
                    {
                        id: 3,
                        title: 'Kursbibliothek enthält/fehlt',
                    },
                    {
                        id: 4,
                        title: 'Mindestens X Kurse',
                    },
                    {
                        id: 5,
                        title: 'Maximal X Kurse',
                    },
                    {
                        id: 6,
                        title: 'Alle Nutzer',
                    },
                ]
            };
        },
        computed: {
            OKourses() {
                return this.$store.getters.OKourseCourses;
            },
        },
        methods: {
            toggleCourse(id, include) {
                console.log(id);
                console.log(include);
                if (include) {
                    if (this.includedCourses.includes(id)) {
                        this.includedCourses = this.includedCourses.filter((el) => el !== id);
                    } else {
                        this.includedCourses.push(id);
                    }
                } else {
                    if (this.excludedCourses.includes(id)) {
                        this.excludedCourses = this.excludedCourses.filter((el) => el !== id);
                    } else {
                        this.excludedCourses.push(id);
                    }
                }
            },
            async sendNotification() {
                let success = true;
                if (this.type) {
                    switch (this.type.id) {
                        case 1:
                            await this.$store.dispatch('createNotification', {
                                type: 'active',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                }
                            }).catch(() => success = false);
                            break;
                        case 2:
                            await this.$store.dispatch('createNotification', {
                                type: 'inactive',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                }
                            }).catch(() => success = false);
                            break;
                        case 3:
                            await this.$store.dispatch('createNotification', {
                                type: 'course',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                    partOfCourse: this.includedCourses,
                                    notPartOfCourse: this.excludedCourses,
                                }
                            }).catch(() => success = false);
                            break;
                        case 4:
                            await this.$store.dispatch('createNotification', {
                                type: 'courseCount',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                    greaterThan: true,
                                    atLeastCount: this.courseAmount,
                                }
                            }).catch(() => success = false);
                            break;
                        case 5:
                            await this.$store.dispatch('createNotification', {
                                type: 'courseCount',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                    greaterThan: false,
                                    atLeastCount: this.courseAmount,
                                }
                            }).catch(() => success = false);
                            break;
                        case 6:
                            await this.$store.dispatch('createNotification', {
                                type: 'all',
                                data: {
                                    title: this.title,
                                    message: this.description,
                                }
                            }).catch(() => success = false);
                            break;
                        default:
                            break;
                    }
                    if (success) this.$router.push('/notifications');
                }
            }
        },
        async mounted() {
            await this.$store.dispatch('retrieveOKourseCourses');
        },
    };
</script>

