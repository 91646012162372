export default {
  setToken(state, token) {
    state.token = token
  },
  setAuthenticated(state, authenticated) {
    state.authenticated = authenticated
  },
  setKnowledge(state, knowledge) {
    state.knowledge = knowledge
  },
  setKnowledgeCategories(state, categories) {
    state.knowledgeCategories = categories
  },
  setCourses(state, courses) {
    state.courses = courses
  },
  setCourseCategories(state, categories) {
    state.courseCategories = categories
  },
  setUsers(state, users) {
    state.users = users
  },
  setTotalUsers(state, count) {
    state.totalUsers = count
  },
  setPosts(state, posts) {
    state.posts = posts
  },
  setTotalPosts(state, count) {
    state.totalPosts = count
  },
  setDogs(state, dogs) {
    state.dogs = dogs
  },
  setTotalDogs(state, count) {
    state.totalDogs = count
  },
  setDogBreeds(state, dogBreeds) {
    state.dogBreeds = dogBreeds
  },
  setTotalDogBreeds(state, count) {
    state.totalDogBreeds = count
  },
  setMarkers(state, markers) {
    state.markers = markers
  },
  setTotalMarkers(state, count) {
    state.totalMarkers = count
  },
  setReports(state, reports) {
    state.reports = reports
  },
  setTotalReports(state, count) {
    state.totalReports = count
  },

  setForumCategories(state, categories) {
    state.forumCategories = categories
  },
  setTotalForumCategories(state, count) {
    state.totalForumCategories = count
  },
  setInterests(state, interests) {
    state.interests = interests
  },
  setTotalInterests(state, count) {
    state.totalInterests = count
  },
  setVaccines(state, vaccines) {
    state.vaccines = vaccines
  },
  setTotalVaccines(state, count) {
    state.totalVaccines = count
  },
  setAnnouncements(state, announcements) {
    state.announcements = announcements
  },
  setTotalAnnouncements(state, count) {
    state.totalAnnouncements = count
  },
  setSkills(state, skills) {
    state.skills = skills
  },
  setTotalSkills(state, count) {
    state.totalSkills = count
  },
  setOKourseCourses(state, courses) {
    state.OKourseCourses = courses
  },
  addToast(state, toast) {
    state.toasts.push({
      id: Date.now().toString(),
      type: toast.type,
      title: toast.title,
    })
  },
  deleteToast(state, toastID) {
    state.toasts = state.toasts.filter(el => el.id !== toastID)
  },
  setNotifications(state, notifications) {
    state.notifications = notifications
  },
  setTotalNotifications(state, count) {
    state.totalNotifications = count
  },
  setAds(state, ads) {
    state.ads = ads
  },
  setTotalAds(state, count) {
    state.totalAds = count
  },
  setProduct(state, product) {
    state.product = product
  },

  // SORT MUTATION ------------------------------------------------------------

  sortUsers(state, { property, asc }) {
    const compareFunction = (a, b) => {
      const propA = a[property].toUpperCase()
      const propB = b[property].toUpperCase()
      let comparison = 0
      if (propA > propB) {
        asc ? (comparison = 1) : (comparison = -1)
      } else if (propA < propB) {
        asc ? (comparison = -1) : (comparison = 1)
      }
      return comparison
    }
    state.users = state.users.sort(compareFunction)
  },

  setCoopPartners(state, coopPartners) {
    state.coopPartners = coopPartners
  },
  setTotalCoopPartners(state, count) {
    state.totalCoopPartners = count
  },
  setCoupons(state, coupons) {
    state.coupons = coupons
  },
  setTotalCoupons(state, count) {
    state.totalCoupons = count
  },
  setCoopRecords(state, coopRecords) {
    state.coopRecords = coopRecords
  },
  setCoopCoupons(state, coopCoupons) {
    state.coopCoupons = coopCoupons
  },
  setCouponCounters(state, couponCounters) {
    state.couponCounters = couponCounters
  },
  setHanseMerkurCoopRecordsTotal(state, total) {
    state.hanseMerkurCoopRecordsTotal = total
  },
  setMemberCount(state, count) {
    state.memberCount = count
  },
}
