<template>
    <div class="width--100 height--100 flex--column">
        <transition name="fade">
            <div class="absolute popup--anchor" v-if="showMainPopup">
                <PopUp :posX="posX" :posY="posY">
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: 1,
                    })">
                        A - Z
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'title',
                        asc: -1,
                    })">
                        Z - A
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: 1,
                    })">
                        Neueste Zuerst
                    </div>
                    <div class="popup--item pad--2 clickable font--s16" @click="sort({
                        attribute: 'createdAt',
                        asc: -1,
                    })">
                        Älteste Zuerst
                    </div>
                </PopUp>
            </div>
        </transition>
        <div class="pad--2">
            <div class="flex flex--space__hor pad--2 bgColor--white shadow--light b-radius--5">
                <v-select :options="options" :clearable="false" v-model="list" class="input--selection width--20 mar--right__1"/>
                <input type="text" class="input--text" placeholder="Suchen..." v-model="searchString"/>
                <div class="mar--left__1">
                    <ActionButton icon="swap-vertical" light="true" @onClick="toggleMainPopup" v-click-outside="() => { this.showMainPopup = false }"/>
                </div>
                <div class="mar--left__1">
                    <ActionButton icon="add" accent="true" @onClick="$router.push('/marker/new/0')"/>
                </div>
            </div>
        </div>
        <transition name="fade" mode="out-in">
            <div class="flex flex--center width--100 height--50" :key="'loader'" v-if="isLoading">
                <loader :loading="true" color="#779d2b" size="50px" />
            </div>
            <div class="flex flex--column list" :key="'list'" v-if="!isLoading">
                <transition-group name="list">
                    <div class="width--100 pad--1 flex flex--center font--w700 font--s16"
                         :key="'results'">
                        <div>
                            {{ totalMarkers + ' Ergebnisse' }}
                        </div>
                    </div>
                    <div v-for="marker in markers" :key="marker._id" class="list--entry" @click="$router.push(`/marker/${marker.markerType}/${marker._id}`)">
                        <div class="width--100 height--100 grid list--entry__grid4 font--s14">
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Typ
                                </div>
                                <div class="font--w700">
                                    {{ types[marker.markerType] }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Titel
                                </div>
                                <div class="font--w700">
                                    {{ marker.title }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Nutzer
                                </div>
                                <div class="font--w700">
                                    {{ marker.user }}
                                </div>
                            </div>
                            <div class="flex flex--column flex--center__hor pad--2 lineHeight--1">
                                <div class="font--s1 color--grey">
                                    Erstellungsdatum
                                </div>
                                <div class="font--w700">
                                    {{ moment(marker.createdAt).format('DD.MM.YYYY') }}
                                </div>
                            </div>
                            <div class="flex flex--center">
                                <ActionButton icon="pencil" accent="true"/>
                            </div>
                        </div>
                    </div>
                    <div class="width--100 pad--2 flex flex--center" :key="'footer'">
                        <ActionButton icon="chevron-back" class="mar--right__1" @onClick="pageBack"/>
                        <div class="font--w700">
                            {{ (page - 1) * 50}} bis {{ page * 50}}
                        </div>
                        <ActionButton icon="chevron-forward" class="mar--left__1" @onClick="pageForward"/>
                    </div>
                </transition-group>
            </div>
        </transition>
    </div>
</template>
<script>
    import 'vue-select/dist/vue-select.css';
    import vSelect from 'vue-select'
    import ActionButton from '@/components/ActionButton';
    import PopUp from "../../components/PopUp";
    export default {
        name: 'MarkerView',
        components: {PopUp, ActionButton, vSelect},
        data() {
            return {
                options: ['Warnungen', 'Ärzte', 'Hundeparks', 'Notfallstationen', 'Unterkünfte', 'Dienstleistungen'],
                list: 'Warnungen',
                page: 1,
                types: {
                    'park': 'Park',
                    'warning': 'Warnung',
                    'doctor': 'Arzt',
                    'shop': 'Dienstleistung',
                    'emergencyStation': 'Notfallstation',
                    'housing': 'Unterkunft',
                },
                typesReverse: {
                    'Hundeparks': 'park',
                    'Warnungen': 'warning',
                    'Ärzte': 'doctor',
                    'Dienstleistungen': 'shop',
                    'Notfallstationen': 'emergencyStation',
                    'Unterkünfte': 'housing',
                },
                posX: null,
                posY: null,
                showMainPopup: false,
                isLoading: true,
                searchString: null,
            };
        },
        computed: {
            markers: {
                get() {
                    return this.$store.getters.markers;
                },
                set(val) {
                    this.$store.commit('setMarkers', val);
                }
            },
            totalMarkers() {
                return this.$store.getters.totalMarkers;
            },
        },
        methods: {
            toggleMainPopup(e) {
                this.posX = e.clientX;
                this.posY = e.clientY;
                this.showMainPopup = !this.showMainPopup;
            },
            async sort({ attribute, asc }) {
                await this.$store.dispatch('retrieveAllMarkers', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    attribute,
                    asc,
                    type: this.typesReverse[this.list],
                    search: this.searchString,
                });
            },
            async search() {
                this.isLoading = true;
                if (this.searchString) {
                    await this.$store.dispatch('retrieveAllMarkers', {
                        limit: 50,
                        skip: (this.page * 50) - 50,
                        type: this.typesReverse[this.list],
                        search: this.searchString,
                    });
                } else {
                    await this.$store.dispatch('retrieveAllMarkers', {
                        limit: 50,
                        skip: (this.page * 50) - 50,
                        type: this.typesReverse[this.list],
                    });
                }
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            async pageForward() {
                if (this.page * 50 < this.totalMarkers) {
                    this.isLoading = true;
                    this.page++;
                    await this.$store.dispatch('retrieveAllMarkers', {
                        limit: 50,
                        skip: (this.page * 50) - 50,
                        type: this.typesReverse[this.list],
                    });
                    await setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            },
            async pageBack() {
                if (this.page > 1) {
                    this.isLoading = true;
                    this.page--;
                    await this.$store.dispatch('retrieveAllMarkers', {
                        limit: 50,
                        skip: (this.page * 50) - 50,
                        type: this.typesReverse[this.list],
                    });
                    await setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                }
            },
        },
        watch: {
            async list(newVal) {
                this.isLoading = true;
                this.page = 1;
                this.searchString = null;
                await this.$store.dispatch('retrieveAllMarkers', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    type: this.typesReverse[newVal],
                });
                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            searchString() {
                this.search();
            },
        },
        async mounted() {
            if (!this.reports || this.reports.isEmpty) {
                this.isLoading = true;
                await this.$store.dispatch('retrieveAllMarkers', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    type: this.typesReverse[this.list],
                });
                await setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            } else {
                await this.$store.dispatch('retrieveAllMarkers', {
                    limit: 50,
                    skip: (this.page * 50) - 50,
                    type: this.typesReverse[this.list],
                });
            }
        }
    }
</script>
